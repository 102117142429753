import './uploadBox.css';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import Compressor from 'compressorjs';

export default function UploadBox({
                                    image,
                                    descripton,
                                    fileSetter,
                                    fileKey,
                                    callback,
                                  }) {

  /**
   * Function that is executed when the user drops a file into
   * the box container.
   */
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const image = acceptedFiles[0];
    if (image) {
      setErrorMessage('');
      new Compressor(image, {
        quality: 0.8, // 0.6 can also be used, but it's not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          fileSetter(compressedResult);
        },
      });
      setFileName(acceptedFiles[0].name);
      callback();
    } else {
      setErrorMessage('*El formato del archivo seleccionado no es valido. Formatos aceptados (PNG, JPEG, JPG)');
    }
  }, []);

  const [fileName, setFileName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ['image/png', 'image/jpg', 'image/jpeg'],
    onFileDialogCancel: () => {
      if (fileName) setErrorMessage('');
      else setErrorMessage('*El formato del archivo seleccionado no es valido. Formatos aceptados (PNG, JPEG, JPG)');
    },
  });

  return (
    <div className="mb-3" {...getRootProps()}>
      <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de <br/>{descripton}</label>
      <div className="fileupload fileupload-new" data-provides="fileupload">
        <div className="fileupload-preview thumbnail fileupload-exists" style={{width: '200px', height: '150px'}}>
        </div>
        <div className="d-flex justify-content-center mt-2 mb-2">
          <span className="btn btn-file">
            <span className="fileupload-new">Seleccione un archivo</span>
            <span className="fileupload-exists">Cambiar</span>
            <input
              type="file"
              {...getInputProps()}
            />
          </span>
          <a href="#" className="btn close fileupload-exists" data-dismiss="fileupload">Quitar</a>
        </div>
      </div>
      {errorMessage && <p className="text-danger" >{errorMessage}</p>}
    </div>
  );
}
