import {
  Field,
  Formik,
  Form,
  useFormik,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { useHistory, useLocation } from "react-router-dom";
import ApiClient from "../../../services/apiclient";
import AlertMessage from "../../alert.component";
import * as Yup from "yup";
import Navbar from "../../front/NavbarI";
import Footer from "../../front/FooterI";
import logo_pabs from "../../../img/logo_pabs.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { UserContext } from "./../../../context/UserProvider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo_balnco from "../../../img/logo_blanco.png";
import { motion } from "framer-motion";
import moment from "moment";
import { PreTramiteCreditoContext } from "../../../context/PreTramiteCreditoProvider";
import Swal from "sweetalert2";
import Modal from "@mui/material/Modal";
import logo from "../../../img/logo.png";

import { Typography, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import Button from "@mui/material/Button";
import LoanType from "../../loan-type.component";
import {
  isBrowser,
  isDesktop,
  isMobile,
  isTablet,
  isSmartTV,
  isConsole,
  isWearable,
  isEmbedded,
  isMobileSafari,
  isChromium,
  isMobileOnly,
  isAndroid,
  isWinPhone,
  isIOS,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge,
  isYandex,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
  isElectron,
  isEdgeChromium,
  isLegacyEdge,
  isWindows,
  isMacOs,
  isMIUI,
  isSamsungBrowser,
} from "react-device-detect";
import intruction_web from "../../../img/instruccion_1_web.png";
import intruction_web_edge from "../../../img/instruccion_1_web_edge.png";
import intruction_web_fire from "../../../img/instruccion_1_web_fire.png";
import intruction_web_safari from "../../../img/instruccion_1_web_safari.jpg";
import intruction_mobile from "../../../img/instruccion_1_movil.jpg";
import intruction_mobile_smg from "../../../img/instruccion_1_movil_smg.jpg";
import intruction_mobile_safari from "../../../img/instruccion_1_movil_safari.jpg";
import mobile_step_1 from "../../../img/mobile_step_1.jpg";
import mobile_step_2 from "../../../img/mobile_step_2.jpg";
import mobile_step_3 from "../../../img/mobile_step_3.jpg";
import mobile_step_4 from "../../../img/mobile_step_4.jpg";
import mobile_step_1_smg from "../../../img/mobile_step_1_smg.jpg";
import mobile_step_2_smg from "../../../img/mobile_step_2_smg.jpg";
import mobile_step_3_smg from "../../../img/mobile_step_3_smg.jpg";
import step_web_1 from "../../../img/step_web_1.png";
import step_web_2 from "../../../img/step_web_2.png";
import step_web_3 from "../../../img/step_web_3.png";
import step_web_1_fire from "../../../img/step_web_1_fire.png";
import step_web_2_fire from "../../../img/step_web_2_fire.png";
import step_web_3_fire from "../../../img/step_web_3_fire.png";
import step_web_4_fire from "../../../img/step_web_4_fire.png";
import step_web_1_edge from "../../../img/step_web_1_edge.png";
import step_web_2_edge from "../../../img/step_web_2_edge.png";
import step_web_3_edge from "../../../img/step_web_3_edge.png";

import step_web_1_safari from "../../../img/step_web_1_safari.jpg";
import step_web_2_safari from "../../../img/step_web_2_safari.jpg";
import step_web_3_safari from "../../../img/step_web_3_safari.jpg";
import step_web_4_safari from "../../../img/step_web_4_safari.jpg";
import step_web_5_safari from "../../../img/step_web_5_safari.jpg";

import mobile_step_1_safari from "../../../img/mobile_step_1_safari.jpg";
import mobile_step_2_safari from "../../../img/mobile_step_2_safari.jpg";
import mobile_step_3_safari from "../../../img/mobile_step_3_safari.jpg";
import mobile_step_4_safari from "../../../img/mobile_step_4_safari.jpg";
import mobile_step_5_safari from "../../../img/mobile_step_5_safari.jpg";
import { validateString } from "../../../utils/StringValidator";

const requierdMessage = "Requerido";
const style3 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const style = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",

  maxHeight: "500px",
  background: "radial-gradient(circle at top left, #005593, #36c9d0)",
  boxShadow: 29,
  overflowY: "auto",
};

const imagesMobileChrome = [
  {
    label:
      "Paso 1.- Haz click en la barra del navegador como se muestra en la imagen..",
    imgPath: mobile_step_1,
  },
  {
    label: "Paso 2.- Busca el menú de Permisos y presiona",
    imgPath: mobile_step_2,
  },
  {
    label: "Paso 3. Marca el campo de ubicación",
    imgPath: mobile_step_3,
  },
  {
    label: "Paso 4. Asegurate de que se muestre asi el campo de ubicación",
    imgPath: mobile_step_4,
  },
];
const imagesMobileSmg = [
  {
    label:
      "Paso 1.- Haz click en la barra del navegador como se muestra en la imagen..",
    imgPath: mobile_step_1_smg,
  },
  {
    label: "Paso 2.- Busca el menú de Permisos y presiona",
    imgPath: mobile_step_2_smg,
  },
  {
    label: "Paso 3. Marca el campo de ubicación",
    imgPath: mobile_step_3_smg,
  },
];
const imagesMobileSafari = [
  {
    label: "Paso 1.- Ve a los ajustes de tu dispositivo.",
    imgPath: mobile_step_1_safari,
  },
  {
    label: "Paso 2.- Busca la aplicación de safari, y presiona.",
    imgPath: mobile_step_2_safari,
  },
  {
    label: "Paso 3. Elige el menú de ubicación.",
    imgPath: mobile_step_3_safari,
  },
  {
    label: "Paso 4. Presiona permitir. Vuelve a la pagina de percapita",
    imgPath: mobile_step_4_safari,
  },
  {
    label:
      "Paso 5. Presiona el icono de actualizar la pagina como se muestra en la imagen",
    imgPath: mobile_step_5_safari,
  },
];
const imagesWebChrome = [
  {
    label:
      "Paso 1.- Haz click en la barra del navegador como se muestra en la imagen..",
    imgPath: step_web_1,
  },
  {
    label: "Paso 2.- Busca el menú de Ubicación y presiona el botón",
    imgPath: step_web_2,
  },
  {
    label: "Paso 3.- Asegurate que haz marcado el campo de ubicación",
    imgPath: step_web_3,
  },
];

const imagesWebFire = [
  {
    label:
      "Paso 1.- Haz click en la barra del navegador como se muestra en la imagen..",
    imgPath: step_web_1_fire,
  },
  {
    label:
      'Paso 2.- Ubica la opcíon que vendra marcada como "Acceder a tu ubicación" y presiona en la opción de Bloqueado temporalmente',
    imgPath: step_web_2_fire,
  },
  {
    label:
      "Paso 3.- Refresca la pagina presionando el boton como se muestra en la imagen.",
    imgPath: step_web_3_fire,
  },
  {
    label:
      'Paso 4.- Nuavamente se mostrará la ventana donde se solicita el permiso para acceder a tu ubicación. Asegurate de presinar "Permitir"',
    imgPath: step_web_4_fire,
  },
];

const imagesWebEdge = [
  {
    label:
      "Paso 1.- Haz click en la barra del navegador como se muestra en la imagen..",
    imgPath: step_web_1_edge,
  },
  {
    label:
      'Paso 2.- Ubica el menú "Permisos para este sitio" y dentro de el ve a la opción de "Ubicación", te mostrará una lista de opciones, elige "Permitir"',
    imgPath: step_web_2_edge,
  },
  {
    label:
      'Paso 3.- Asegurate que aparezca seleccionada la opcion de "Permitir"',
    imgPath: step_web_3_edge,
  },
];
const imagesWebSafari = [
  {
    label:
      'Paso 1.- Haz click en el menú de inicio de tu equipo y ve a la opción de "Preferencias del sistema".',
    imgPath: step_web_1_safari,
  },
  {
    label: 'Paso 2. -Dirigete a la opción de "Seguridad y Privacidad".',
    imgPath: step_web_2_safari,
  },
  {
    label:
      "Paso 3.- Haz click en el candado para que peudas editar los permisos.",
    imgPath: step_web_3_safari,
  },

  {
    label:
      "Paso 4.- Asegurate que  el icono del candado aparezca como se muestra en la imagen. Ubica la opción de safari y deja seleccionada la casilla como se muesta en la imagen.",
    imgPath: step_web_4_safari,
  },

  {
    label:
      'Paso 5.- Cuando ingreses al sitio te mostrara la opción para permitir la ubicación. Asegurate de seleccionar "OK"',
    imgPath: step_web_5_safari,
  },
];

const principalImageByBrowser = [
  {
    isMobile: true,
    browser: "chrome",
    img: intruction_mobile,
  },
  {
    isMobile: true,
    browser: "samsumg",
    img: intruction_mobile_smg,
  },
  {
    isMobile: true,
    browser: "safari",
    img: intruction_mobile_safari,
  },
  {
    isMobile: false,
    browser: "chrome",
    img: intruction_web,
  },
  {
    isMobile: false,
    browser: "firefox",
    img: intruction_web_fire,
  },
  {
    isMobile: false,
    browser: "edge",
    img: intruction_web_edge,
  },
  {
    isMobile: false,
    browser: "safari",
    img: intruction_web_safari,
  },
];
const validationSchema = Yup.object().shape({
  city: Yup.string().required(requierdMessage),
  contract: Yup.string().required(requierdMessage),
});

localStorage.setItem("loanType", "Titulo");
localStorage.getItem("loanType");

export default function ValidacionPabs() {
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const query = useQuery();
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [ciudades, setCiudades] = useState([]);
  const [login, setLogin] = React.useState(false);
  const { userMain, updateUser } = React.useContext(UserContext);
  const [phoneStatus, setPhoneStatus] = React.useState(true);
  const [userLoan, setUserLoan] = useState({
    city: "",
    knowAboutPercapita: "",
    contract: "",
    firstName: "",
    secondName: "",
    lastFName: "",
    lastMName: "",
    dateOfBirth: "",
    state: "",
    gender: "",
    email: "",
    phone: "",
    attached1: false,
    attached2: false,
    attached3: false,
    password: "",
    confirmPassword: "",
    val1: "",
    val2: "",
    val3: "",
    val4: "",
    val5: "",
    val6: "",
    userId: localStorage.getItem("prspect"),
  });
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [shown, setShown] = React.useState(false);
  const [phoneChange, setPhoneChange] = React.useState(false);
  const [newPhone, setNewPhone] = React.useState("");
  const [fromLogin, setFromLogin] = React.useState(false);
  const [sendCode, setSendCode] = React.useState({
    phone: "",
    send: false,
  });

  const [utmMedium, setUtmMedium] = React.useState("");
  const [utmCampaign, setUtmCampaign] = React.useState("");
  const [utmSource, setUtmSource] = React.useState("");

  const [loading, setLoading] = useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [isSmartphone, setIsSmartphone] = useState(false);
  const [errorUbication, setErrorUbication] = useState(false);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [maxSteps, setMaxSteps] = useState(0);
  const [images, setImages] = useState(null);
  const [imageInstructionOne, setImageInstructionOne] = useState(null);
  const [phoneRegistered, setPhoneRegistered] = useState(true);
  const [givenPhoneAlreadyRegistered, setGivenPhoneAlreadyRegistered] = useState(false);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const StyledModal = styled(Modal)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledModal2 = styled(Modal)(({ theme }) => ({}));

  const StyledPaper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: isSmartphone ? theme.spacing(1, 1, 2) : theme.spacing(1, 10, 3),
    borderRadius: "8px",
    outline: "none",
    position: "absolute" as "absolute",
    top: isSmartphone ? "1%" : "10%",
    left: isSmartphone ? "1%" : "20%",
    right: isSmartphone ? "1%" : "20%",

    // transform: "translate(-50%, -50%)",
    // maxWidth: "800px",
  }));

  const StyledPaper2 = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: isSmartphone ? theme.spacing(1, 1, 2) : theme.spacing(1, 10, 3),
    borderRadius: "8px",
    outline: "none",
    position: "absolute" as "absolute",
    top: isSmartphone ? "1%" : "10%",
    left: isSmartphone ? "1%" : "20%",
    right: isSmartphone ? "1%" : "20%",

    // transform: "translate(-50%, -50%)",
    // maxWidth: "800px",
  }));
  const apiClient = ApiClient.getInstance();
  const history = useHistory();

  useEffect(() => {
    apiClient
      .getCityWithoutLogin()
      .then((ciudades) => {
        setCiudades(ciudades);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };

  useEffect(() => {
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "register-init" &&
        credito.typeLoanMarketing === "Titulo"
      )
        var data = {
          processName: "register-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "register-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);

  const createPABS = async (values: any) => {
    setLoading(true);
    //setPabsNumber(values.contract);

    await apiClient
      .userContract(values.contract, values.city)
      .then((value) => {
        //   setLoading(false);
        //   setLoanAmount(function (prevData: any) {
        //     var oldObj = { ...prevData };
        //     oldObj.upper = value.upper;
        //     oldObj.lower = value.lower;
        //     return { ...oldObj };
        //   });
        //   localStorage.setItem("upper",value.upper);
        //   localStorage.setItem("lower",value.lower);
        //   nextStep();
        if (localStorage.getItem("preCreditoLocal")) {
          var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
          if (
            credito.stepMarketing != "register-finished" &&
            credito.typeLoanMarketing === "Titulo"
          )
            var data = {
              processName: "register-finished",
            };
          updatePreCredito({
            ...preCredito,
            stepMarketing: "register-finished",
          });
          updateMarketing(credito.idProcessMarketing, data);
        }
        history.push("/PABS");
      })
      .catch((err) => {
        if (localStorage.getItem("preCreditoLocal")) {
          var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
          if (
            credito.stepMarketing != "register-init" &&
            credito.typeLoanMarketing === "Titulo"
          )
            var data = {
              processName: "register-denied-contract-pabs",
            };
          updatePreCredito({
            ...preCredito,
            stepMarketing: "register-denied-contract-pabs",
          });
          updateMarketing(credito.idProcessMarketing, data);
        }
        if (err.message == "Contract number was not found.") {
          history.push("/RECHAZADO/pabs/contract-not-found");
        } else if (err.message == "La ciudad elegida no existe.") {
          history.push("/RECHAZADO/pabs/city-not-found");
        } else if (
          err.message ==
          "El título registrado ya fue utilizado. Cuenta con otro titulo vigente?"
        ) {
          history.push("/RECHAZADO/pabs/validated-user");
        } else if (
          err.message ==
          "El nombre regisrado no coincide con el número de contrato. Favor de revisar los datos"
        ) {
          history.push("/RECHAZADO/pabs/user-does-not-match");
        } else if (
          err.message ==
          "El título registrado está en Suspensión. Cuenta con otro titulo vigente?"
        ) {
          history.push("/RECHAZADO/pabs/suspended-account");
        } else if (
          err.message ==
          "El título registrado cuenta con un saldo mayor a $2,000.00. Cuenta con otro titulo pagado o saldo menor a $2,000.00?"
        ) {
          history.push("/RECHAZADO/pabs/major-balance");
        } else if (err.message == "Prestamo denegado.") {
          history.push("/RECHAZADO/pabs/loan-denied");
        } else {
          history.push("/RECHAZADO/pabs/not-found");
        }
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function handleChangeDate(event: any) {
    console.log(event.target.value);
    let date = event.target.value.split("/");
    setFieldValue("dateOfBirth", date[2] + "-" + date[0] + "-" + date[1]);
  }
  function handleChangePhone(event: any) {
    //var regExp = /[a-zA-Z+-/*. ]/g;
    var regExp = /^[0-9]*$/;
                
    if(regExp.test(event.target.value)){
      console.log("if 1");
      if ( event.target.value.length <= 10 ) {
        console.log("if 2");
        setFieldValue("phone", event.target.value.toString());
      } else {
        console.log("else 2");
      }
    } else {
      console.log("else 1");
    }
    
    if ( event.target.value.length == 10 ) {
      setPhoneRegistered(false);
    }
  }
  function handleChangePhoneBlur(event: any) {
    // if(sendCode.phone !== ""){ // se envío sms
    //     //sendSms
    //     if(sendCode.phone !== values.phone){
    //         setPhoneChange(true)
    //     }
    // }
    if (!errors.phone && values.phone !== "") {
      apiClient.validatePhoneTitulo(values.phone, values.utm_medium, values.utm_campaign, values.utm_source).then((data) => {
        if (data.error && (typeof data.error === 'string' || data.error instanceof String)) {
          Swal.fire({
            title: "Telefono registrado",
            text: "Encontramos que tu teléfono ya ha sido registrado, te invitamos a iniciar sesión presionando el botón (Iniciar Sesión)",
            icon: "warning",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Salir",
            confirmButtonText: "Iniciar Sesión",
          }).then((result) => {
            if (result.isConfirmed) {
              return history.push("/login");
            } else {
              return  window.location.replace("https://percapita.mx");
            }
          });

          setPhoneRegistered(true);
          setGivenPhoneAlreadyRegistered(true);
          // setOpenMessage(true);
          // setMessage(
          //   "Lo sentimos, el telefono ingresado, ya se encuentra registrado, si ya tienes una cuenta, inicia sesión"
          // );
          // setSeverity("error");
          // return;
        } else if (data.error) {

          setPhoneRegistered(true);
          setGivenPhoneAlreadyRegistered(true);

          /*setIsLoading(false);
          setOpenMessage(true);
          setMessage("Ocurrió un error en la conexión. Intenta más tarde o verifica que tengas una conexión activa a internet");
          setSeverity("error");*/

          Swal.fire({
            title: "Error de conexión",
            text: "Ocurrió un error en la conexión. Intenta más tarde o verifica que tengas una conexión activa a internet",
            icon: "error",
            allowOutsideClick: false,
            //showCancelButton: true,
            confirmButtonColor: "#d33",
            //cancelButtonColor: "#d33",
            //cancelButtonText: "Salir",
            confirmButtonText: "Salir",
          }).then((result) => {
              return  window.location.replace("https://percapita.mx");
          });
        } else {
          setPhoneRegistered(false);
          setGivenPhoneAlreadyRegistered(true);
        }
      }).catch((err) => {

        setPhoneRegistered(true);
        setGivenPhoneAlreadyRegistered(true);

        /*setIsLoading(false);
        setOpenMessage(true);
        setMessage("Ocurrió un error en la conexión. Intenta más tarde o verifica que tengas una conexión activa a internet");
        setSeverity("error");*/

        Swal.fire({
          title: "Error de conexión",
          text: "Ocurrió un error en la conexión. Intenta más tarde o verifica que tengas una conexión activa a internet",
          icon: "error",
          allowOutsideClick: false,
          //showCancelButton: true,
          confirmButtonColor: "#d33",
          //cancelButtonColor: "#d33",
          //cancelButtonText: "Salir",
          confirmButtonText: "Salir",
        }).then((result) => {
            return  window.location.replace("https://percapita.mx");
        });
      });
    }
  }

  function handleChangeKnowAboutPercapita(event: any) {
    
    console.log("handleChangeKnowAboutPercapita = " + event.target.value);
    if (event.target.value === "Selecciona una opción:") {
      errors.knowAboutPercapita = null;
      setFieldValue("knowAboutPercapita", null);
    }
    else {
      errors.knowAboutPercapita = event.target.value;
      setFieldValue("knowAboutPercapita", event.target.value);
    }
  };

  const handleBlurDate = (e) => console.log(e.target.value);
  function handleBlurPassword(event: any) {
    console.log(event);
    setFieldValue("confirmPassword", event.target.value);
    console.log(errors.confirmPassword);
    // if (!errors.confirmPassword) {
    //   apiClient
    //     .signup(values.phone, values.password, "personal")
    //     .then((res) => res.json())
    //     .then((data) => {
    //       console.log(data);
    //       localStorage.setItem("cxdc", values.phone);
    //       localStorage.setItem("fwcd", values.password);
    //       if (data.error) {
    //         setIsLoading(false);
    //         localStorage.clear();
    //         setOpenMessage(true);
    //         setMessage(
    //           data.status >= 500 ? "Error del servidor" : data.error.message
    //         );
    //         setSeverity("error");
    //         setLogin(false);
    //         return;
    //       } else {
    //         setOpenMessage(true);
    //         setMessage("Telefono registrado exitosamente");
    //       }
    //       setLogin(true);
    //       localStorage.setItem("userId", data.user);
    //       localStorage.setItem("USER_ID", data.user);
    //     })
    //     .catch((err) => {
    //       setIsLoading(false);
    //       setOpenMessage(true);
    //       setMessage("Error en el servidor");
    //       setSeverity("error");
    //     });
    // }
  }

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: "",
      secondName: "",
      lastFName: "",
      lastMName: "",
      dateOfBirth: "",
      state: "",
      gender: "",
      email: "",
      phone: "",
      attached1: false,
      attached2: false,
      attached3: false,
      password: "",
      confirmPassword: "",
      val1: "",
      val2: "",
      val3: "",
      val4: "",
      val5: "",
      val6: "",
      userId: localStorage.getItem("prspect"),
      contract: "",
      city: "",
      knowAboutPercapita: "",
      utm_medium: "",
      utm_campaign: "",
      utm_source: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Tienes que ingresar la calle."),
      lastFName: Yup.string().required("Tienes que ingresar la calle."),
      lastMName: Yup.string().required("Tienes que ingresar la calle."),
      dateOfBirth: Yup.date()
        .required("Ingresa una fecha válida.")
        .max(
          moment().subtract(18, "year"),
          "Lo sentimos, no cumples con la edad mínima para continuar con el proceso"
        ),
      //state: Yup.string().required("Tienes que ingresar la calle."),
      email: Yup.string()
        .notRequired()
        .min(8)
        .nullable()
        .transform((value) => (!!value ? value : null)),
      phone: Yup.string()
        .required("Tienes que ingresar la calle.")
        /*.matches(
          /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/
        ),*/
        .matches(
          /^([0-9]{10})$/
        ),
      attached1: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "Field must be checked"),
      attached2: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "Field must be checked"),
      password: Yup.string().required("Tienes que ingresar la calle."),
      confirmPassword: Yup.string()
        .required("Please confirm your password")
        .oneOf([Yup.ref("password"), null], "Passwords don't match."),
      contract: 
        Yup.lazy((value: string) => {
          const patterns: RegExp[] = [
              /^[a-zA-Z]\d{6}$/,          // Regla caracter y 6 digitos X000001
              /^\d[a-zA-Z]{2}\d{6}$/,        // Regla 1 dígito seguido de dos caracteres y 6 digitos 1AJ002556
              /^[a-zA-Z]{2}\d{6}$/        // Regla dos caracteres y 6 digitos AX002556
          ];

          // recorre arreglod e expresiones regulares para validar si cumple con todas las validaciones
                for (const pattern of patterns) {
                    if (pattern.test(value)) {
                      console.log(44444)
                        return Yup.string().required().matches(pattern, 'El número de contrato no coincide. Valida tu contrato Ej. A000001 (incluye todos los 0 ceros)');
                    }
                }

                return Yup.string().test('invalid', 'El número de contrato no coincide. Valida tu contrato Ej. A000001 (incluye todos los 0 ceros)', () => false);
            }),
        //  Yup.string()
        // .min(7, "Tu titulo debe tener al menos 7 dígitos. Ej. A002358")
        // .max(9)        
        // .matches(/^[a-zA-Z]\d{6}$/,'El número de contrato no coincide. Valida tu contrato Ej. A000001 (incluye todos los 0)')        
        // .matches(/^\d[a-zA-Z]{2}\d{6}$/,'El número de contrato no coincide. Valida tu contrato Ej. A000001 (incluye todos los 0)')        
        // .matches(/^[a-zA-Z]{2}\d{6}$/,'El número de contrato no coincide. Valida tu contrato Ej. A000001 (incluye todos los 0)')
        // .required("Debes ingresar un título"),
      city: Yup.string().required("Tienes que ingresar la calle."),
      knowAboutPercapita: Yup.string().required("Tienes que seleccionar la forma que conociste sobre Percapita."),
      gender: Yup.string().required("Tienes que seleccionar el género."),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      let code =
        values.val1 +
        values.val2 +
        values.val3 +
        values.val4 +
        values.val5 +
        values.val6;
      const dataUser = {
        userDetails: {
          firstName: values.firstName,
          secondName: values.secondName,
          lastFName: values.lastFName,
          lastMName: values.lastMName,
          birthDate: new Date(values.dateOfBirth).toISOString(), //values.dateOfBirth.substr(0, 10), //
          email: values.email,
          sex: values.gender,
        },
        publicidad: values.attached3,
        knowAboutPercapita: values.knowAboutPercapita,
        credentials: {
          user: values.phone,
          password: values.password,
        }
      };
      await apiClient
        .activateAccountSMSV2(code, dataUser)
        .then(async (data) => {
          setOpenMessage(true);
          setSeverity("success");
          setMessage("Tu cuenta ha sido activada");
          console.log(
            localStorage.getItem("cxdc"),
            localStorage.getItem("fwcd")
          );
          localStorage.setItem("TOKEN", data.token);
          localStorage.setItem("USER_ID", data.userId);

          apiClient.token = data.token;
          apiClient.userId = data.userId;
          if (data.fullName) {
            updateUser({
              ...userMain,
              nombre: data.fullName || data.fullName,
              telefono: data.phone,
            });
          } else {
            updateUser({
              ...userMain,
              telefono: data.phone,
            });
          }
          await createPABS(values);
          // if (localStorage.getItem("cxdc") && localStorage.getItem("fwcd")) {
          // console.log(data);
          // await apiClient
          //   // .login(localStorage.getItem("cxdc"), localStorage.getItem("fwcd"))
          //   .login(values.phone, values.password)
          //   .then((res) => res.json())
          //   .then(async (a) => {
          //     localStorage.removeItem("cxdc");
          //     localStorage.removeItem("fwcd");
          //     if (a.error) {
          //       localStorage.clear();
          //       setOpenMessage(true);
          //       const statusCode = a.error?.status || a.error.statusCode;
          //       setMessage(
          //         statusCode === 401
          //           ? "Telefono o contraseña invalidos"
          //           : a.error.message
          //       );
          //       setSeverity("error");

          //       // if(statusCode === 403)
          //       //   return history.push("/step2");
          //       //   else return history.push("/login");
          //     }

          //     localStorage.setItem("TOKEN", a.token);
          //     localStorage.setItem("USER_ID", a.userId);

          //     apiClient.token = a.token;
          //     apiClient.userId = a.userId;
          //     if (a.fullName) {
          //       updateUser({
          //         ...userMain,
          //         nombre: a.fullName || a.fullName,
          //         telefono: a.phone,
          //       });
          //     } else {
          //       updateUser({
          //         ...userMain,
          //         telefono: a.phone,
          //       });
          //     }
          //     try {
          //       await apiClient.termsConditions({
          //         publicidad: values.attached3,
          //       });
          //     } catch (error) {
          //       setIsLoading(false);
          //       setOpenMessage(true);
          //       setMessage("Ha ocurrido un error al guardar los datos.");
          //       setSeverity("error");
          //       return;
          //     }
          //     const data = {
          //       firstName: values.firstName,
          //       secondName: values.secondName,
          //       lastFName: values.lastFName,
          //       lastMName: values.lastMName,
          //       birthDate: new Date(values.dateOfBirth).toISOString(), //values.dateOfBirth.substr(0, 10), //
          //       email: values.email,
          //       sex: values.gender,
          //     };
          //     await apiClient
          //       .createUserDetails(data)
          //       .then((res) => res.json())
          //       .then(async (data) => {
          //         if (data.error) {
          //           setOpenMessage(true);
          //           setMessage(data.error.message);
          //           setSeverity("error");
          //         }
          //         const fullName = `${values.firstName} ${values.secondName} ${values.lastFName} ${values.lastMName}`;
          //         updateUser({
          //           ...userMain,
          //           nombre: fullName,
          //         });
          //         //return history.push("/solicitud-datos3");
          //         await createPABS(values);
          //       })
          //       .catch((err) => {
          //         setIsLoading(false);
          //         setOpenMessage(true);
          //         setMessage(
          //           "Favor de verificar que la informacion sea correcta."
          //         );
          //         setSeverity("error");
          //       });
          //   }).catch((reason) => {
          //     setIsLoading(false)
          //     setOpenMessage(true);
          //     setMessage(reason.message);
          //     setSeverity("error");
          //   });
          // }
          //return history.push("/login");
        })
        .catch((reason) => {
          setIsLoading(false);
          if (reason.message === "Código inválido") {
            setFieldValue("val1", "");
            setFieldValue("val2", "");
            setFieldValue("val3", "");
            setFieldValue("val4", "");
            setFieldValue("val5", "");
            setFieldValue("val6", "");
          }
          console.log(reason);
          setIsLoading(false);
          setOpenMessage(true);
          setMessage(reason.message);
          setSeverity("error");
        });
    },
  });
  useEffect(() => {
    console.log("error -   confirm", errors.confirmPassword);
    if (!errors.confirmPassword && !errors.phone && values.phone !== "") {
      if (fromLogin !== true) {
        setIsLoading(true);
        apiClient
          .signup(values.phone, values.password, "pabs")
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            localStorage.setItem("cxdc", values.phone);
            localStorage.setItem("fwcd", values.password);
            localStorage.setItem("USER_ID", data.user);
            if (data.error) {
              setIsLoading(false);
              //localStorage.clear();
              setOpenMessage(true);
              setMessage(
                data.status >= 500 ? "Error del servidor" : data.error.message
              );
              setSeverity("error");
              setLogin(false);

              return;
            }
            setLogin(true);
            setPhoneStatus(false);
            setPhoneRegistered(true);
            localStorage.setItem("userId", data.user);
            const nextSibling = document.querySelector(
              "#lastFName"
            ) as HTMLElement | null;
            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            setOpenMessage(true);
            setMessage("Error en el servidor");
            setSeverity("error");
          });
      } else {
        setPhoneStatus(false);
        setPhoneRegistered(true);
        setLogin(true);
      }
    }
  }, [errors.confirmPassword]);
  function handleChangeCode(e) {
    console.log(e.target);
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    console.log(parseInt(fieldIndex, 10));

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 6) {
        // Get the next input field
        console.log(parseInt(fieldIndex, 10));
        const nextSibling = document.querySelector(
          `input[name=val-${parseInt(fieldIndex, 10) + 1}]`
        ) as HTMLElement | null;

        // If found, focus the next field
        if (nextSibling !== null) {
          console.log(nextSibling);
          nextSibling.focus();
        }
      } else if (parseInt(fieldIndex, 10) === 6) {
        handleSubmit();
      }
    }
    setFieldValue([`val${fieldIndex}`].toString(), value);
  }

  function handleChangeCodePhone(e) {
    console.log(e.target);
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 16) {
        // Get the next input field
        console.log(parseInt(fieldIndex, 10));
        const nextSibling = document.querySelector(
          `input[name=val-${parseInt(fieldIndex, 10) + 1}]`
        ) as HTMLElement | null;

        // If found, focus the next field
        if (nextSibling !== null) {
          console.log(nextSibling);
          nextSibling.focus();
        }
      } else if (parseInt(fieldIndex, 10) === 16) {
        handleSubmit();
      }
    }
    setFieldValue([`val${fieldIndex - 10}`].toString(), value);
  }

  const checkFromRoute = async () => {
    var creditoLocal = "";
    if (localStorage.getItem("userLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      setAmount(parseInt(userLocal.preAmount));
    }

    const from = query.get("from");
    if (from === "Login") {
      setFieldValue("phone", localStorage.getItem("cxdc"));
      setFieldValue("password", localStorage.getItem("fwcd"));
      setFieldValue("confirmPassword", localStorage.getItem("fwcd"));
      setFromLogin(true);
      setPhoneStatus(false);
    }

    

    const utm_medium = query.get("utm_medium");
    const utm_campaign = query.get("utm_campaign");
    const utm_source = query.get("utm_source");
    setUtmMedium(utm_medium);
    setUtmCampaign(utm_campaign);
    setUtmSource(utm_source);
    setFieldValue("utm_medium", utm_medium);
    setFieldValue("utm_campaign", utm_campaign);
    setFieldValue("utm_source", utm_source);
  };

  const sendSms = async () => {
    if (fromLogin === true) {
      let phone = localStorage.getItem("cxdc");
      await apiClient
        .sendSmsCreditByPhone(phone)
        .then((data) => {
          setOpenMessage(true);
          setSeverity("success");
          setMessage("Código reenviado con éxito");
        })
        .catch((err) => {
          setOpenMessage(true);
          setMessage("Error al enviar código por SMS");
          setSeverity("error");
        });
    } else {
      let phone = localStorage.getItem("cxdc");
      if ( phone != null ) {
        await apiClient
          .sendSmsCreditByPhone(phone)
          .then((res) => {
            setOpenMessage(true);
            setSeverity("success");
            setMessage("Código reenviado por SMS con éxito");
          })
          .catch((err) => {
            setOpenMessage(true);
            setMessage("Error al enviar código por SMS");
            setSeverity("error");
          });
      } else {
        await apiClient
          .sendSmsCredit()
          .then((res) => {
            setOpenMessage(true);
            setSeverity("success");
            setMessage("Código reenviado por SMS con éxito");
          })
          .catch((err) => {
            setOpenMessage(true);
            setMessage("Error al enviar código por SMS");
            setSeverity("error");
          });
      }
    }
  };
  const sendWhatsApp = async () => {
    if (fromLogin === true) {
    //if (true) {
      let phone = localStorage.getItem("cxdc");
      await apiClient
        .sendWhatsAppCreditByPhone(phone)
        .then((res) => {
          setOpenMessage(true);
          setSeverity("success");
          setMessage("Código reenviado por WhatsApp con éxito");
        })
        .catch((err) => {
          setOpenMessage(true);
          setMessage("Error al enviar código por WhatsApp");
          setSeverity("error");
        });
    } else {
      let phone = localStorage.getItem("cxdc");
      if ( phone != null ) {
        await apiClient
          .sendWhatsAppCreditByPhone(phone)
          .then((res) => {
            setOpenMessage(true);
            setSeverity("success");
            setMessage("Código reenviado por WhatsApp con éxito");
          })
          .catch((err) => {
            setOpenMessage(true);
            setMessage("Error al enviar código por WhatsApp");
            setSeverity("error");
          });
      } else {
        await apiClient
          .sendWhatsAppCredit()
          .then((res) => {
            setOpenMessage(true);
            setSeverity("success");
            setMessage("Código reenviado por WhatsApp con éxito");
          })
          .catch((err) => {
            setOpenMessage(true);
            setMessage("Error al enviar código por WhatsApp");
            setSeverity("error");
          });
      }
    }
  };
  useEffect(() => {
    checkFromRoute();
  }, []);
  useEffect(() => {
    console.log(errors.contract);
  }, [errors.contract]);

  useEffect(() => {
    const checkLocationPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        permissionStatus.onchange = () => {
          console.log(
            `geolocation permission status has changed to ${permissionStatus.state}`
          );
          if (permissionStatus.state === "granted") {
            return setErrorUbication(false);
          } else {
            return setErrorUbication(true);
          }
        };
        console.log("obect position", permissionStatus);
        if (permissionStatus.state === "granted") {
        } else if (permissionStatus.state === "prompt") {
          console.log("Location permission prompt");
          return setOpen2(true);
          // You can show a UI element to request permission if needed
        } else if (permissionStatus.state === "denied") {
          console.log("Location permission denied");
          return setErrorUbication(true);
        }
      } catch (error) {
        console.error("Error checking location permission:", error);
      }
    };

    checkLocationPermission();
  }, []);

  useEffect(() => {
    const checkIsSmartphone = async () => {
      //alert(isSamsungBrowser);
      console.log(
        "isBrowser ->" + isBrowser,
        " isDesktop ->" + isDesktop,
        " isSmartphone ->" + isSmartphone,
        " isTablet ->" + isTablet,
        " isSmartTV ->" + isSmartTV,
        " isConsole ->" + isConsole,
        " isWearable ->" + isWearable,
        " isEmbedded ->" + isEmbedded,
        " isSmartphoneSafari ->" + isMobileSafari,
        " isChromium ->" + isChromium,
        " isSmartphoneOnly ->" + isMobileOnly,
        " isAndroid ->" + isAndroid,
        " isWinPhone ->" + isWinPhone,
        " isIOS ->" + isIOS,
        " isChrome ->" + isChrome,
        " isFirefox ->" + isFirefox,
        " isSafari ->" + isSafari,
        " isOpera ->" + isOpera,
        " isIE ->" + isIE,
        " isEdge ->" + isEdge,
        " isYandex ->" + isYandex,
        " isIOS13 ->" + isIOS13,
        " isIPad13 ->" + isIPad13,
        " isIPhone13 ->" + isIPhone13,
        " isIPod13 ->" + isIPod13,
        " isElectron ->" + isElectron,
        " isEdgeChromium ->" + isEdgeChromium,
        " isLegacyEdge ->" + isLegacyEdge,
        " isWindows ->" + isWindows,
        " isMacOs ->" + isMacOs,
        " isMIUI ->" + isMIUI,
        " isSamsungBrowser ->" + isSamsungBrowser
      );
      const screenWidth = window.innerWidth;
      setIsSmartphone(screenWidth <= 768); // Ajusta este valor según tus necesidades
      if (screenWidth <= 768) {
        const isSamsungBrowser2 = navigator.userAgent.match(/SamsungBrowser/i);
        if (isChrome) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "chrome" && obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesMobileChrome);
          setMaxSteps(imagesMobileChrome.length);
        } else if (isSamsungBrowser2) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "samsumg" && obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesMobileSmg);
          setMaxSteps(imagesMobileSmg.length);
        } else if (isMobileSafari) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "safari" && obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesMobileSafari);
          setMaxSteps(imagesMobileSafari.length);
        } else {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "chrome" && obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesMobileSafari);
          setMaxSteps(imagesMobileSafari.length);
        }
      } else {
        if (isChrome) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "chrome" && !obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesWebChrome);
          setMaxSteps(imagesWebChrome.length);
        } else if (isEdge) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "edge" && !obj.isMobile
          );
          console.log(index, principalImageByBrowser[index]);
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesWebEdge);
          setMaxSteps(imagesWebEdge.length);
        } else if (isFirefox) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "firefox" && !obj.isMobile
          );
          console.log(index, principalImageByBrowser[index]);
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesWebFire);
          setMaxSteps(imagesWebFire.length);
          return;
        } else if (isSafari) {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "safari" && !obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesWebSafari);
          setMaxSteps(imagesWebSafari.length);
        } else {
          let index = principalImageByBrowser.findIndex(
            (obj) => obj.browser === "chrome" && !obj.isMobile
          );
          setImageInstructionOne(principalImageByBrowser[index].img);
          setImages(imagesWebSafari);
          setMaxSteps(imagesWebSafari.length);
        }
      }
    };

    // Verificación inicial
    checkIsSmartphone();

    // Escucha de eventos para cambios en el tamaño de la ventana
    window.addEventListener("resize", checkIsSmartphone);

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener("resize", checkIsSmartphone);
    };
  }, []);
  const processLocation = () => {
    console.log("clicked");
    setOpen2(false);
    /* se ejecuta si los permisos son concedidos y se encuentra una ubicación*/
    function onSucccess(position) {}

    /*se ejecuta si el permiso fue denegado o no se puede encontrar una ubicación*/
    function onError() {
      setErrorUbication(true);
      console.log("ocurrio un error o no hay permisos para ver la ubicación");
    }

    var config = {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 27000,
    };

    /*así llamamos la función getCurrentPosition*/
    navigator.geolocation.getCurrentPosition(onSucccess, onError, config);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="grey-background"
    >
      <Navbar isLoggedIn={!phoneStatus} />
      <br />
      { !phoneStatus ? (
      <LoanType
        loanType={localStorage.getItem("loanType")}
        userValidated={false}
      />
      ) : ""}
      <Modal
        open={open2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledPaper>
          <Typography variant="h5" sx={{ marginBottom: "1px" }}>
            <div className="d-flex justify-content-center mb-1">
              <img src={logo} alt="PRADO'S" width="100" className="img-fluid" />
            </div>
          </Typography>
          <Typography>
            ¡Hola! Para ofrecerte la mejor experiencia personalizada, Percapita
            necesita acceder a tu ubicación.
          </Typography>
          <Typography>
            A continuación aparecerá una ventana solicitando tu permiso para
            obtener tu ubicacion, es necesario aceptarlo para poder continuar
            con tu proceso.
          </Typography>
          {isSmartphone ? (
            <Typography variant="h5" sx={{ marginBottom: "12px" }}>
              <div className="d-flex justify-content-center mb-1">
                {imageInstructionOne && (
                  <img
                    src={imageInstructionOne}
                    alt="PRADO'S"
                    width="200"
                    className="img-fluid"
                  />
                )}
              </div>
            </Typography>
          ) : (
            <Typography variant="h5" sx={{ marginBottom: "16px" }}>
              <div className="d-flex justify-content-center mb-1">
                {imageInstructionOne && (
                  <img
                    src={imageInstructionOne}
                    alt="PRADO'S"
                    width="400"
                    className="img-fluid"
                  />
                )}
              </div>
            </Typography>
          )}

          <Button
            variant="outlined"
            color="primary"
            onClick={() => processLocation()}
            //onPress={() => processLocation()}

            tabIndex={1}
            sx={{
              marginTop: isSmartphone ? "5px" : "16px",
              left: isSmartphone ? "30%" : "45%",
            }}
          >
            Aceptar
          </Button>
          {/* <button 
            type="button" 
            className="btn btn-shadow"
            style={{
              marginTop: isSmartphone ? "5px" : "16px",
              left: isSmartphone ? "30%" : "45%",
            }}
            onClick={() => processLocation()}
            >
            Aceptar
          </button> */}
        </StyledPaper>
      </Modal>
      <Modal
        open={errorUbication}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledPaper2>
          <Typography variant="h5" sx={{ marginBottom: "1px" }}>
            <div className="d-flex justify-content-center mb-1">
              <img src={logo} alt="PRADO'S" width="100" className="img-fluid" />
            </div>
          </Typography>
          <Typography>
            ¡Hola! Para ofrecerte la mejor experiencia personalizada, Percapita
            necesita acceder a tu ubicación.
          </Typography>
          <Typography>
            A continuación te presentamos una serie de pasos que deberás seguir
            para habilitar la ubicación en tu navegador.
          </Typography>
          {images && (
            <Box
              sx={{
                maxWidth: !isSmartphone ? 700 : 400,
                flexGrow: 1,
                margin: "auto",
              }}
              justifyContent={"center"}
            >
              <Paper
                square
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: 50,
                  pl: 2,
                  bgcolor: "background.default",
                }}
              >
                <Typography>{images[activeStep].label}</Typography>
              </Paper>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                //enableMouseEvents
              >
                {images.map((step, index) => (
                  <div key={step.label}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="img"
                        sx={{
                          height: !isSmartphone ? 420 : 200,
                          display: "block",
                          maxWidth: !isSmartphone ? 700 : 400,
                          overflow: "hidden",
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={step.imgPath}
                        alt={step.label}
                      />
                    ) : null}
                  </div>
                ))}
              </SwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Siguiente
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Anterior
                  </Button>
                }
              />
            </Box>
          )}
        </StyledPaper2>
      </Modal>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 mb-1"
      >
        <div className="card card-monto">
          <div className="container">
            <h6 className="text-credit text-end p-1">Monto solicitado:</h6>
            <h2 className="text-light-blue text-end">
              {" "}
              {amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="row mb-1"
      >
        <div className="col-3">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="card card-monto">
              <div className="container">
                <h6 className="text-credit text-end p-1">Monto solicitado:</h6>
                <h2 className="text-light-blue text-end">
                  {" "}
                  {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="text-center">
            <img
              src={logo_pabs}
              alt="PRADO'S"
              className="img-fluid mb-4"
              width="70"
            />
            <h3 className="sol1-tittle">
              Bienvenido a tu solicitud <br />
              de préstamo con Título PABS
            </h3>
          </div>
        </div>
        <div className="col-3"></div>
      </motion.div>
      {/* STEPS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-none d-sm-none d-md-block p-0 m-0 "
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step-blue">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 "
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step">
            <h5 className="numbre-step-blue">1</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      {/* CARDS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-none d-sm-none d-md-block d-flex justify-content-center mt-2 container"
      >
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            {fromLogin === false ? (
              <div className="row">
                <div className="col-6 col-sm-6">
                  <h6 className="text-grey">
                    Antes de continuar, necesitas crear una contraseña. <br />Tú número de teléfono se usará como tu usuario de acceso. Para eso vamos a validar tu número de teléfono enviándote un código por SMS. <br /><br />En caso de olvidar tu contraseña podrás recuperarla en cualquier momento.{" "}
                    <br />
                    <br /> <br />{" "}
                  </h6>
                </div>
                <input
                    type="input"
                    id="utm_medium"
                    name="utm_medium"
                    className="hidden-input"
                    value={utmMedium}
                  />
                <input
                    type="input"
                    id="utm_campaign"
                    name="utm_campaign"
                    className="hidden-input"
                    value={utmCampaign}
                  />
                <input
                    type="input"
                    id="utm_source"
                    name="utm_source"
                    className="hidden-input"
                    value={utmSource}
                  />
                <div className="col-6 col-sm-6">
                  <label htmlFor="phone" className="form-label sol1-tittle">
                    Número de celular *
                  </label>
                  <input
                    type="text"
                    className={
                      errors.phone ? "form-control  is-invalid" : "form-control"
                    }
                    id="phone"
                    aria-describedby="emailHelp"
                    onChange={handleChangePhone}
                    value={values.phone}
                    onBlur={handleChangePhoneBlur}
                    tabIndex={1}
                    disabled={givenPhoneAlreadyRegistered}
                  />
                  {errors.phone && (
                    <div className="invalid-feedback">
                      Ingresa un teléfono válido
                    </div>
                  )}
                </div>
                <div className="col-6 col-sm-6">
                  <label htmlFor="password" className="form-label sol1-tittle">
                    Contraseña *
                  </label>
                  <input
                    type={shown ? "text" : "password"}
                    className={
                      errors.password
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="password"
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                    value={values.password}
                    onBlur={handleBlur}
                    autoComplete="false"
                    tabIndex={2}
                    disabled={phoneRegistered}
                  />
                </div>

                <div className="col-6 col-sm-6">
                  <label
                    htmlFor="confirmPassword"
                    className="form-label sol1-tittle"
                  >
                    Confirma contraseña *
                  </label>
                  <input
                    type={shown ? "text" : "password"}
                    className={
                      errors.confirmPassword
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="confirmPassword"
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                    value={values.confirmPassword}
                    onBlur={handleBlurPassword}
                    tabIndex={3}
                    disabled={phoneRegistered}
                  />
                  {errors.confirmPassword &&
                    values.confirmPassword.length >= values.password.length &&
                    values.password.length > 0 && (
                      <div className="invalid-feedback">
                        Las contraseñas no coinciden
                      </div>
                    )}
                  <a href="/login">
                    <span className="pt-0 rBlack">¿Ya tienes una cuenta?</span>
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}

            <hr className="mt-4 mb-4" />
            <p className="txt-blue-strong">
              Rellena los campos vacíos con tus datos
            </p>
            <form>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label sol1-tittle">
                      Ciudad donde solicitaste tu titulo*
                    </label>
                    <select
                      className={
                        errors.city && touched.city
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="city"
                      name="city"
                      onChange={handleChange}
                      value={values.city}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                      tabIndex={4}
                    >
                      <option selected>Ciudad</option>
                      {ciudades.map((ciudad) => (
                        <option key={ciudad.id} value={ciudad.name}>
                          {ciudad.nameDescription}
                        </option>
                      ))}
                    </select>
                    {errors.city && touched.city && (
                      <div className="invalid-feedback">Elige una ciudad</div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="contract"
                      className="form-label sol1-tittle"
                    >
                      Número de título PABS*
                      <span style={{ fontSize: "12px"}} className="text-danger">Anota los 7, 8 o 9 caracteres que conforman tu número de título. Ej: XYZ000099, XY999999 o X009999</span>
                    </label>
                    <input
                      type="input"
                      className={
                        errors.contract && touched.contract
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="contract"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "contract",
                          validateString(value.trim().toUpperCase())
                        );
                      }}
                      value={values.contract}
                      onBlur={handleBlur}
                      maxLength={9}
                      disabled={phoneStatus}
                      tabIndex={5}
                    />
                    {errors.contract && touched.contract && (
                      <div className="invalid-feedback">
                        { errors.contract }
                      </div>
                    )}
                  </div>
                </div>
                <hr className="mt-4 mb-4" />
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="mb-3">
                    <p style={{ fontSize: "12px"}} className="text-danger">Escribe tu nombre como aparece en tu INE</p>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="lastFName"
                      className="form-label sol1-tittle"
                    >
                      Apellido paterno*
                    </label>
                    <input
                      type="input"
                      className={
                        errors.lastFName && touched.lastFName
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="lastFName"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "lastFName",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.lastFName}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                      tabIndex={6}
                    />
                    {errors.lastFName && touched.lastFName && (
                      <div className="invalid-feedback">
                        Ingresa tu apellido paterno
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="lastMName"
                      className="form-label sol1-tittle"
                    >
                      Apellido materno*
                    </label>
                    <input
                      type="input"
                      className={
                        errors.lastMName && touched.lastMName
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="lastMName"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "lastMName",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.lastMName}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                      tabIndex={7}
                    />
                    {errors.lastMName && touched.lastMName && (
                      <div className="invalid-feedback">
                        Ingresa tu apellido materno
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="firstName"
                      className="form-label sol1-tittle"
                    >
                      Primer nombre*
                    </label>
                    <input
                      type="input"
                      className={
                        errors.firstName && touched.firstName
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="firstName"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "firstName",
                          validateString(value.toUpperCase())
                        );
                      }}
                      onBlur={handleBlur}
                      value={values.firstName}
                      disabled={phoneStatus}
                      tabIndex={8}
                    />
                    {errors.firstName && touched.firstName && (
                      <div className="invalid-feedback">Ingresa un nombre</div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="secondName"
                      className="form-label sol1-tittle"
                    >
                      Segundo nombre
                    </label>
                    <input
                      type="input"
                      className={
                        errors.secondName && touched.secondName
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="secondName"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "secondName",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.secondName}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                      tabIndex={9}
                    />
                    {errors.secondName && touched.secondName && (
                      <div className="invalid-feedback">
                        Ingresa tu segundo nombre
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6"></div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-1">
                    <label
                      htmlFor="birthDate"
                      className="form-label sol1-tittle"
                    >
                      Fecha de nacimiento *
                    </label>

                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3}>
                                                <DatePicker 
                                                disableFuture                                                
                                                //label="Fecha nacimiento"
                                                openTo="day"
                                                toolbarPlaceholder="dff"
                                                toolbarFormat="//"
                                                views={[  'month', 'day', 'year']}
                                                value={date}
                                                onChange= {(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                disabled={phoneStatus}
                                                renderInput={
                                                     (params) => 
                                                        <TextField 
                                                            {...params}
                                                            size="medium"
                                                            color="primary"
                                                            
                                                            name="dateOfBirth"
                                                            onChange={handleChangeDate}
                                                            value={values.dateOfBirth}
                                                            onBlur={handleBlurDate}
                                                            disabled={phoneStatus}
                                              />}
                                                />
                                            </Stack>
                                            </LocalizationProvider> */}
                    <input
                      type="date"
                      className={
                        errors.dateOfBirth && touched.dateOfBirth
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="dateOfBirth"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.dateOfBirth}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                      tabIndex={10}
                    />
                    {errors.dateOfBirth && (
                      <div className="invalid-feedback">
                        {errors.dateOfBirth}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-4">
                    <label htmlFor="gender" className="form-label sol1-tittle">
                      Género *
                    </label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className={
                          errors.gender
                            ? " form-check-input is-invalid"
                            : "form-check-input"
                        }
                        type="radio"
                        name="gender"
                        id="gender"
                        value="femenino"
                        checked={values.gender === "femenino"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={phoneStatus}
                        tabIndex={11}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Mujer
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className={
                          errors.gender
                            ? " form-check-input is-invalid"
                            : "form-check-input"
                        }
                        type="radio"
                        name="gender"
                        id="gender"
                        value="masculino"
                        checked={values.gender === "masculino"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={phoneStatus}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        Hombre
                      </label>
                    </div>
                    {errors.gender && touched.gender && (
                      <div className="invalid-feedback">Elige un género</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label sol1-tittle">
                      Correo electrónico*
                    </label>
                    <input
                      type="email"
                      className={
                        errors.email && touched.email
                          ? " form-control  is-invalid"
                          : "form-control"
                      }
                      id="email"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                      tabIndex={12}
                    />
                    {errors.email && touched.email && (
                      <div className="invalid-feedback">
                        Ingresa tu correo electrónico
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="knowAboutPercapita" className="form-label sol1-tittle">
                      ¿Cómo conoció Percapita?*
                    </label>
                    <select
                      className={
                        errors.knowAboutPercapita && touched.knowAboutPercapita
                          ? ' form-select slect-blue text-light is-invalid'
                          : 'form-select slect-blue text-light'
                      }
                      aria-label="Default select example"
                      id="knowAboutPercapita"
                      name="knowAboutPercapita"
                      onChange={handleChangeKnowAboutPercapita}
                      value={values.knowAboutPercapita}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                    >
                      <option selected>Selecciona una opción:</option>
                      <option value="RECOMENDACIÓN">RECOMENDACIÓN</option>
                      <option value="REDES SOCIALES">REDES SOCIALES</option>
                      <option value="GOOGLE">GOOGLE</option>
                      <option value="WHATSAPP">WHATSAPP</option>
                      <option value="SMS">SMS</option>
                      <option value="VENDEDOR">VENDEDOR</option>
                      <option value="RECUPERADOR PABS">RECUPERADOR PABS</option>
                      <option value="COBRANZA PABS">COBRANZA PABS</option>
                      <option value="RECUPERADOR PERCAPITA">RECUPERADOR PERCAPITA</option>
                      <option value="OTRO">OTRO</option>
                    </select>
                    {errors.knowAboutPercapita && touched.knowAboutPercapita && (
                      <div className="invalid-feedback">Elige una opción</div>
                    )}
                  </div>
                </div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="container">
                    <h6 className="text-grey">
                      Antes de continuar, debemos validar tu número. <br />
                      Da click aquí una vez que hayas puesto tu celular.{" "}
                    </h6>
                    <div className="form-check">
                      <input
                        className={
                          errors.attached1
                            ? "form-check-input checkbox-terms  is-invalid"
                            : "form-check-input checkbox-terms"
                        }
                        type="checkbox"
                        //value=""
                        id="attached1"
                        //checked={values.attached1}
                        onChange={handleChange}
                        disabled={
                          errors.password || errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={handleBlur}
                      />
                      <label
                        className="form-check-label text-check"
                        htmlFor="attached1"
                      >
                        Acepto el Aviso de Privacidad
                      </label>
                      {errors.attached1 && !errors.confirmPassword && (
                        <div className="invalid-feedback">
                          Debes aceptar el aviso de privacidad
                        </div>
                      )}
                    </div>
                    <div className="form-check">
                      <input
                        className={
                          errors.attached2
                            ? "form-check-input checkbox-terms  is-invalid"
                            : "form-check-input checkbox-terms"
                        }
                        type="checkbox"
                        //value=""
                        id="attached2"
                        //checked={values.attached2}
                        onChange={handleChange}
                        disabled={
                          errors.password || errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={handleBlur}
                      />

                      <label
                        className="form-check-label text-check"
                        htmlFor="attached2"
                      >
                        Acepto las obligaciones del Contrato de licencia
                      </label>
                      {errors.attached2 && !errors.confirmPassword && (
                        <div className="invalid-feedback">
                          Debes aceptar el contrato de licencia
                        </div>
                      )}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input checkbox-terms"
                        type="checkbox"
                        value=""
                        id="attached3"
                        checked={values.attached3}
                        onChange={handleChange}
                        disabled={
                          errors.password || errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={handleBlur}
                      />
                      <label
                        className="form-check-label text-check"
                        htmlFor="flexCheckDefault"
                      >
                        Acepto recibir noticias, promociones e información
                        acerca de mi cuenta
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                                
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-4">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Confirma tu contraseña</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Numero de celular *</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="container">
                                        <h6 className="text-grey">Antes de continuar, debemos validar tu número. <br />
                                            Da click aquí una vez que hayas puesto tu celular. </h6>
                                        <div className="form-check">
                                            <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto el Aviso de Privacidad
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto las obligaciones del Contrato de licencia
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto recibir noticias, promociones e información acerca de mi cuenta
                                            </label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-start mt-5 mb-4">
                                        <a href='/pabs/3' className="btn btn-shadow">
                                            Enviar código
                                        </a>
                                    </div>
                                </div>
                            </div> */}
              <div className="text-center mt-1">
                <p>
                  Por favor, ingresa el código que te enviamos a tu celular:
                </p>
                <p className="sol1-tittle">Código</p>
              </div>
              <div className="d-flex justify-content-center">
                <div className="p-1">
                  <input
                    type="string"
                    className="form-control input-number "
                    id="val-1"
                    aria-describedby="emailHelp"
                    name="val-1"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val1}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-2"
                    aria-describedby="emailHelp"
                    name="val-2"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val2}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-3"
                    aria-describedby="emailHelp"
                    name="val-3"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val3}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-4"
                    aria-describedby="emailHelp"
                    name="val-4"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val4}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-5"
                    aria-describedby="emailHelp"
                    name="val-5"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val5}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-6"
                    aria-describedby="emailHelp"
                    name="val-6"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val6}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
              </div>
            </form>
            <div className="row mt-2">
            <div className="col-2"></div>
            {phoneStatus === true ? (
              ''
            ) : (
              <>
              <div className="col-8 text-center">
                  <a onClick={sendSms}
                    className="btn btn-secondary">
                    Reenviar código por SMS
                  </a>
              </div>
              <div className="row mt-2">
                <div className="col-12 text-center">
                  <a
                    onClick={sendWhatsApp}
                    className="btn btn-success"
                  >Reenviar código por WhatsApp <i className="fa-brands fa-whatsapp fa-xl"></i>
                  </a>
                </div>
              </div>
              </>
            )}
            <div className="col-2"></div>
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 d-sm-block d-md-none p-0 m-0"
      >
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            {fromLogin === false ? (
              <div className="row">
                <div className="col-12 col-sm-12">
                  <h6 className="text-grey">
                    Antes de continuar, necesitas crear una contraseña. <br />Tú número de teléfono se usará como tu usuario de acceso. Para eso vamos a validar tu número de teléfono enviándote un código por SMS. <br /><br />En caso de olvidar tu contraseña podrás recuperarla en cualquier momento.{" "}
                    <br />
                    <br /> <br />{" "}
                  </h6>
                </div>
                <div className="col-12 col-sm-12">
                  <label htmlFor="phone" className="form-label sol1-tittle">
                    Número de celular *
                  </label>
                  <input
                    type="text"
                    className={
                      errors.phone ? "form-control  is-invalid" : "form-control"
                    }
                    id="phone"
                    aria-describedby="emailHelp"
                    onChange={handleChangePhone}
                    value={values.phone}
                    onBlur={handleChangePhoneBlur}
                    tabIndex={1}
                    disabled={givenPhoneAlreadyRegistered}
                  />
                  {errors.phone && (
                    <div className="invalid-feedback">
                      Ingresa un teléfono válido
                    </div>
                  )}
                </div>
                <div className="col-12 col-sm-12">
                  <label htmlFor="password" className="form-label sol1-tittle">
                    Contraseña *
                  </label>
                  <input
                    type={shown ? "text" : "password"}
                    className={
                      errors.password
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="password"
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                    value={values.password}
                    onBlur={handleBlur}
                    autoComplete="false"
                    disabled={phoneRegistered}
                  />
                </div>

                <div className="col-12 col-sm-12">
                  <label
                    htmlFor="confirmPassword"
                    className="form-label sol1-tittle"
                  >
                    Confirma contraseña *
                  </label>
                  <input
                    type={shown ? "text" : "password"}
                    className={
                      errors.confirmPassword
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="confirmPassword"
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                    value={values.confirmPassword}
                    onBlur={handleBlurPassword}
                    disabled={phoneRegistered}
                  />
                  {errors.confirmPassword &&
                    values.confirmPassword.length >= values.password.length &&
                    values.password.length > 0 && (
                      <div className="invalid-feedback">
                        Las contraseñas no coinciden
                      </div>
                    )}
                  <a href="/login">
                    <span className="pt-0 rBlack">¿Ya tienes una cuenta?</span>
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}

            <hr className="mt-4 mb-4" />
            <p className="txt-blue-strong">
              Rellena los campos vacíos con tus datos
            </p>
            <form>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label sol1-tittle">
                      Ciudad donde solicitaste tu titulo*
                    </label>
                    <select
                      className={
                        errors.city && touched.city
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                      aria-label="Default select example"
                      id="city"
                      name="city"
                      onChange={handleChange}
                      value={values.city}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                    >
                      <option selected>Ciudad</option>
                      {ciudades.map((ciudad) => (
                        <option key={ciudad.id} value={ciudad.name}>
                          {ciudad.nameDescription}
                        </option>
                      ))}
                    </select>
                    {errors.city && touched.city && (
                      <div className="invalid-feedback">Elige una ciudad</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="contract"
                      className="form-label sol1-tittle"
                    >
                      Número de título PABS*
                      <span style={{ fontSize: "12px"}} className="text-danger">Anota los 7, 8 o 9 caracteres que conforman tu número de título. Ej: XYZ000099, XY999999 o X009999</span>
                    </label>
                    <input
                      type="input"
                      className={
                        errors.contract && touched.contract
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="contract"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "contract",
                          validateString(value.trim().toUpperCase())
                        );
                      }}
                      value={values.contract}
                      onBlur={handleBlur}
                      maxLength={9}
                      disabled={phoneStatus}
                    />
                    {errors.contract && touched.contract && (
                      <div className="invalid-feedback">
                        { errors.contract }
                      </div>
                    )}
                  </div>
                </div>
                <hr className="mt-4 mb-4" />
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="mb-3">
                    <p style={{ fontSize: "12px"}} className="text-danger">Escribe tu nombre como aparece en tu INE</p>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="lastFName"
                      className="form-label sol1-tittle"
                    >
                      Apellido paterno*
                    </label>
                    <input
                      type="input"
                      className={
                        errors.lastFName && touched.lastFName
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="lastFName"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "lastFName",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.lastFName}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                    />
                    {errors.lastFName && touched.lastFName && (
                      <div className="invalid-feedback">
                        Ingresa tu apellido paterno
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="lastMName"
                      className="form-label sol1-tittle"
                    >
                      Apellido materno*
                    </label>
                    <input
                      type="input"
                      className={
                        errors.lastMName && touched.lastMName
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="lastMName"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "lastMName",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.lastMName}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                    />
                    {errors.lastMName && touched.lastMName && (
                      <div className="invalid-feedback">
                        Ingresa tu apellido materno
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="firstName"
                    className="form-label sol1-tittle"
                  >
                    Primer nombre*
                  </label>
                  <input
                    type="input"
                    className={
                      errors.firstName && touched.firstName
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="firstName"
                    aria-describedby="emailHelp"
                    onChange={(e) => {
                      const value = e.target.value || "";
                      setFieldValue(
                        "firstName",
                        validateString(value.toUpperCase())
                      );
                    }}
                    value={values.firstName}
                    onBlur={handleBlur}
                    disabled={phoneStatus}
                  />
                  {errors.firstName && touched.firstName && (
                    <div className="invalid-feedback">Ingresa un nombre</div>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="secondName"
                    className="form-label sol1-tittle"
                  >
                    Segundo nombre
                  </label>
                  <input
                    type="input"
                    className={
                      errors.secondName && touched.secondName
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="secondName"
                    aria-describedby="emailHelp"
                    onChange={(e) => {
                      const value = e.target.value || "";
                      setFieldValue(
                        "secondName",
                        validateString(value.toUpperCase())
                      );
                    }}
                    value={values.secondName}
                    onBlur={handleBlur}
                    disabled={phoneStatus}
                  />
                  {errors.secondName && touched.secondName && (
                    <div className="invalid-feedback">
                      Ingresa tu segundo nombre
                    </div>
                  )}
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6"></div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-1">
                    <label
                      htmlFor="birthDate"
                      className="form-label sol1-tittle"
                    >
                      Fecha de nacimiento *
                    </label>
                    <input
                      type="date"
                      className={
                        errors.dateOfBirth && touched.dateOfBirth
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="dateOfBirth"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.dateOfBirth}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                    />
                    {errors.dateOfBirth && (
                      <div className="invalid-feedback">
                        {errors.dateOfBirth}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-4">
                    <label htmlFor="gender" className="form-label sol1-tittle">
                      Género *
                    </label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className={
                          errors.gender && touched.gender
                            ? " form-check-input is-invalid"
                            : "form-check-input"
                        }
                        type="radio"
                        name="gender"
                        id="gender"
                        value="femenino"
                        checked={values.gender === "femenino"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={phoneStatus}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Mujer
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className={
                          errors.gender
                            ? " form-check-input is-invalid"
                            : "form-check-input"
                        }
                        type="radio"
                        name="gender"
                        id="gender"
                        value="masculino"
                        checked={values.gender === "masculino"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={phoneStatus}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        Hombre
                      </label>
                    </div>
                    {errors.gender && touched.gender && (
                      <div className="invalid-feedback">Elige un género</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label sol1-tittle">
                      Correo electrónico*
                    </label>
                    <input
                      type="email"
                      className={
                        errors.email && touched.email
                          ? " form-control  is-invalid"
                          : "form-control"
                      }
                      id="email"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                    />
                    {errors.email && touched.email && (
                      <div className="invalid-feedback">
                        Ingresa tu correo electrónico
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="knowAboutPercapita" className="form-label sol1-tittle">
                      ¿Cómo conoció Percapita?*
                    </label>
                    <select
                      className={
                        errors.knowAboutPercapita && touched.knowAboutPercapita
                          ? ' form-select slect-blue text-light is-invalid'
                          : 'form-select slect-blue text-light'
                      }
                      aria-label="Default select example"
                      id="knowAboutPercapita"
                      name="knowAboutPercapita"
                      onChange={handleChangeKnowAboutPercapita}
                      value={values.knowAboutPercapita}
                      onBlur={handleBlur}
                      disabled={phoneStatus}
                    >
                      <option selected>Selecciona una opción:</option>
                      <option value="RECOMENDACIÓN">RECOMENDACIÓN</option>
                      <option value="REDES SOCIALES">REDES SOCIALES</option>
                      <option value="GOOGLE">GOOGLE</option>
                      <option value="WHATSAPP">WHATSAPP</option>
                      <option value="SMS">SMS</option>
                      <option value="VENDEDOR">VENDEDOR</option>
                      <option value="RECUPERADOR PABS">RECUPERADOR PABS</option>
                      <option value="COBRANZA PABS">COBRANZA PABS</option>
                      <option value="RECUPERADOR PERCAPITA">RECUPERADOR PERCAPITA</option>
                      <option value="OTRO">OTRO</option>
                    </select>
                    {errors.knowAboutPercapita && touched.knowAboutPercapita && (
                      <div className="invalid-feedback">Elige una opción</div>
                    )}
                  </div>
                </div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="container">
                    <h6 className="text-grey">
                      Antes de continuar, debemos validar tu número. <br />
                      Da click aquí una vez que hayas puesto tu celular.{" "}
                    </h6>
                    <div className="form-check">
                      <input
                        className={
                          errors.attached1
                            ? "form-check-input checkbox-terms  is-invalid"
                            : "form-check-input checkbox-terms"
                        }
                        type="checkbox"
                        //value=""
                        id="attached1"
                        //checked={values.attached1}
                        onChange={handleChange}
                        disabled={
                          errors.password || errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={handleBlur}
                      />
                      <label
                        className="form-check-label text-check"
                        htmlFor="attached1"
                      >
                        Acepto el Aviso de Privacidad
                      </label>
                      {errors.attached1 && !errors.confirmPassword && (
                        <div className="invalid-feedback">
                          Debes aceptar el aviso de privacidad
                        </div>
                      )}
                    </div>
                    <div className="form-check">
                      <input
                        className={
                          errors.attached2
                            ? "form-check-input checkbox-terms  is-invalid"
                            : "form-check-input checkbox-terms"
                        }
                        type="checkbox"
                        //value=""
                        id="attached2"
                        //checked={values.attached2}
                        onChange={handleChange}
                        disabled={
                          errors.password || errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={handleBlur}
                      />

                      <label
                        className="form-check-label text-check"
                        htmlFor="attached2"
                      >
                        Acepto las obligaciones del Contrato de licencia
                      </label>
                      {errors.attached2 && !errors.confirmPassword && (
                        <div className="invalid-feedback">
                          Debes aceptar el contrato de licencia
                        </div>
                      )}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input checkbox-terms"
                        type="checkbox"
                        value=""
                        id="attached3"
                        checked={values.attached3}
                        onChange={handleChange}
                        disabled={
                          errors.password || errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={handleBlur}
                      />
                      <label
                        className="form-check-label text-check"
                        htmlFor="flexCheckDefault"
                      >
                        Acepto recibir noticias, promociones e información
                        acerca de mi cuenta
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-1">
                <p>
                  Por favor, ingresa el código que te enviamos a tu celular:
                </p>
                <p className="sol1-tittle">Código</p>
              </div>
              <div className="d-flex justify-content-center">
                <div className="p-1">
                  <input
                    type="string"
                    className="form-control input-number "
                    id="val-11"
                    aria-describedby="emailHelp"
                    name="val-11"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val1}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-12"
                    aria-describedby="emailHelp"
                    name="val-12"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val2}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-13"
                    aria-describedby="emailHelp"
                    name="val-13"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val3}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-14"
                    aria-describedby="emailHelp"
                    name="val-14"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val4}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-15"
                    aria-describedby="emailHelp"
                    name="val-15"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val5}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-16"
                    aria-describedby="emailHelp"
                    name="val-16"
                    onChange={handleChangeCodePhone}
                    maxLength={1}
                    value={Object.keys(errors).length > 0 ? "" : values.val6}
                    disabled={Object.keys(errors).length > 0 ? true : false}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-2"></div>
                {phoneStatus === true ? (
                  ''
                ) : (
                  <>
                    <div className="col-8 text-center">
                      <a onClick={sendSms}
                        className="btn btn-secondary">
                        Reenviar código por SMS
                      </a>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12 text-center">
                        <a
                          onClick={sendWhatsApp}
                          className="btn btn-success"
                        >Reenviar código por WhatsApp <i className="fa-brands fa-whatsapp fa-xl"></i>
                        </a>
                      </div>
                    </div>
                  </>
                )}

                <div className="col-2"></div>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
      <Footer />
    </motion.div>
  );
}
