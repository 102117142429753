import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import ApiClient, { IAddress, IAddressPersonal } from "../../../services/apiclient";
import { nextStep } from "../../../store/actions/PrestamosPersonalesActions";
import ".././../../css/style.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../../front/FooterI";
import logo_balnco from "../../../img/logo_blanco.png";
import flechai from "../../../img/flechai.png";
import flechad from "../../../img/flechaD.png";
import { motion } from "framer-motion";
import Navbar from "../../front/NavbarI";
import { PreTramiteCreditoContext } from "../../../context/PreTramiteCreditoProvider";
import { TramiteCreditoContext } from "../../../context/TramiteCreditoProvider";
import {validateString} from "../../../utils/StringValidator"
import { useHistory } from "react-router-dom";
import AlertMessage from "../../alert.component";
import LoanType from "../../loan-type.component";
//import { useForm }  from "react-hook-form";
const AddressForm = ({ nominaId, nextStep }) => {
  const apiClient = ApiClient.getInstance();

  const [colonias, setColonias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [preInfo, setPreInfo] = useState(null);
  const [amount, setAmount] = useState(0);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const history = useHistory();
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const { Credito, updateCredito, deleteCredito } = React.useContext(
    TramiteCreditoContext
  );
  const [zipData, setZipData] = useState<IAddressPersonal>({
    ciudad: "",
    estado: "",
    municipio: "",
    delegation: "",
    colonias: [],
  });
  const [zipDataCobranza, setZipDataCobranza] = useState<IAddress>({
    ciudad: "",
    estado: "",
    municipio: "",
    colonias: [],
  });

  localStorage.getItem("loanType");

  const [zip, setZip] = useState({
    postalCode: "",
  });

  const handleClose = () => {
    setOpenMessage(false);
  };
  /*const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();*/
  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };
  useEffect(() => {
    apiClient.getUserPreInfo().then((data) => {
      if (!data.error) {
        setFieldValue("street", data.street);
        setFieldValue("exteriorNumber", data.exteriorNumber);
        setFieldValue("interiorNumber", data.interiorNumber);
        setFieldValue("propertyType", data.propertyType);
        setFieldValue("postalCode", data.postalCode);
        setFieldValue("antiquity", data.antiquity);
        setFieldValue("antiquityMonth", data.antiquityMonth);
        setFieldValue("homePhone", data.homePhone);
        console.log(data.antiquityMonth, data.antiquity);
      }
    });
    if (localStorage.getItem("userLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      setAmount(parseInt(userLocal.preAmount));
    }
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "address-form-init" &&
        credito.typeLoanMarketing === "Personal"
      )
        var data = {
          processName: "address-form-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "address-form-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);

  function handleSameAddress(e) {
    console.log(e.target.checked);
    let isChecked = e.target.checked;
    if ( isChecked ) {
      
      setFieldValue("streetCobranza", values.street);
      setFieldValue("exteriorNumberCobranza", values.exteriorNumber);
      setFieldValue("interiorNumberCobranza", values.interiorNumber);
      setFieldValue("postalCodeCobranza", values.postalCode);
      setFieldValue("addressCobranza", values.address);
      setFieldValue("suburbCobranza", values.suburb);
      setFieldValue("cityCobranza", values.city);
      setFieldValue("stateCobranza", values.state);
      setFieldValue("countryCobranza", values.country);
    } else {
      setFieldValue("streetCobranza", '');
      setFieldValue("exteriorNumberCobranza", '');
      setFieldValue("interiorNumberCobranza", '');
      setFieldValue("postalCodeCobranza", '');
      setFieldValue("addressCobranza",'');
      setFieldValue("suburbCobranza", '');
      setFieldValue("cityCobranza", '');
      setFieldValue("stateCobranza", '');
      setFieldValue("countryCobranza", '');
    }
    
  };

  const handleZipBlur = (event: React.FocusEvent) => {
    
    if (errors.postalCode) {
      setMessage("Debes introducir un código postal válido.");
      setSeverity("error");
      setOpenMessage(true);
      return;
    }

    apiClient
      .getAdrressFromZipCodePersonal(values.postalCode)
      .then((data) => {
        if ( data.estado != 'error' ) {
          console.log("codigo postal correcto");
          setFieldValue("state", data.estado);
          setFieldValue("city", data.ciudad || data.municipio);
          setFieldValue("delegation", data.municipio || data.ciudad);
          setFieldValue("country", "Mexico");
          setFieldValue("postalCode", values.postalCode);
          //setZip(values.userData.address?.postalCode.toString());
          data.delegation = data.municipio || data.ciudad;
          setZipData(data);
        } else {
          console.log("codigo postal INcorrecto");

          setFieldValue("state", "");
          setFieldValue("city","");
          setFieldValue("country", "");
          setFieldValue("delegation", "");
          setZipData({
            ciudad: "",
            estado: "",
            municipio: "",
            delegation: "",
            colonias: [],
          });

          setMessage("Debes introducir un código postal válido.");
          setSeverity("error");
          setOpenMessage(true);
        }
      })
      .catch((e) => {

        setFieldValue("state", "");
        setFieldValue("city","");
        setFieldValue("country", "");
        setFieldValue("delegation", "");
        setZipData({
          ciudad: "",
          estado: "",
          municipio: "",
          delegation: "",
          colonias: [],
        });

        setMessage("Debes introducir un código postal válido.");
        setSeverity("error");
        setOpenMessage(true);
      });
  };
  function handleChangePhone(event: any) {
    //var regExp = /[a-zA-Z+-/*. ]/g;
    var regExp = /^[0-9]*$/;
                
    if(regExp.test(event.target.value)){
      console.log("if 1");
      if ( event.target.value.length <= 10 ) {
        console.log("if 2");
        setFieldValue("homePhone", event.target.value.toString());
      } else {
        console.log("else 2");
      }
    } else {
      console.log("else 1");
    }
  }

  const handleZipBlurCobranza = (event: React.FocusEvent) => {
    if (errors.postalCodeCobranza) {
      setMessage("Debes introducir un código postal válido.");
      setSeverity("error");
      setOpenMessage(true);
      return;
    }

    apiClient
      .getAdrressFromZipCode(values.postalCodeCobranza)
      .then((data) => {
        if ( data.estado != 'error' ) {
          setFieldValue("stateCobranza", data.estado);
          setFieldValue("cityCobranza", data.ciudad || data.municipio);
          setFieldValue("countryCobranza", "Mexico");
          setFieldValue("postalCodeCobranza", values.postalCodeCobranza);
          //setZip(values.userData.address?.postalCode.toString());
          setZipDataCobranza(data);
        } else {

          setFieldValue("stateCobranza", "");
          setFieldValue("cityCobranza","");
          setFieldValue("countryCobranza", "");
          setZipDataCobranza({
            ciudad: "",
            estado: "",
            municipio: "",
            colonias: [],
          });

          setMessage("Debes introducir un código postal válido.");
          setSeverity("error");
          setOpenMessage(true);
        }
      })
      .catch((e) => {
        setMessage("Debes introducir un código postal válido.");
        setSeverity("error");
        setOpenMessage(true);
      });
  };

  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: {
      street: "",
      interiorNumber: "",
      exteriorNumber: "",
      suburb: "",
      postalCode: "",
      city: "",
      delegation: "",
      state: "",
      country: "",
      homePhone: "",
      antiquity: 0,
      antiquityMonth: 0,
      propertyType: "",
      address:"",
      
      streetCobranza: "",
      interiorNumberCobranza: "",
      exteriorNumberCobranza: "",
      suburbCobranza: "",
      postalCodeCobranza: "",
      cityCobranza: "",
      stateCobranza: "",
      countryCobranza: "",
      addressCobranza:"",
    },
    validationSchema: Yup.object().shape({
      street: Yup.string().required("Requerido"),
      interiorNumber: Yup.string(),
      exteriorNumber: Yup.string().required("Requerido"),
      suburb: Yup.string().required("Requerido"),
      city: Yup.string().required("Requerido"),
      country: Yup.string().required("Requerido"),
      state: Yup.string().required("Requerido"),
      postalCode: Yup.string().required("Requerido"),
      homePhone: Yup.string().required("Requerido"),
      antiquity: Yup.number().nullable().required("Requerido"),
      antiquityMonth: Yup.number().nullable().required("Requerido"),
      address: Yup.string().required("Requerido"),
      propertyType: Yup.string().required("Requerido"),

      streetCobranza: Yup.string().required("Requerido"),
      interiorNumberCobranza: Yup.string(),
      exteriorNumberCobranza: Yup.string().required("Requerido"),
      suburbCobranza: Yup.string().required("Requerido"),
      postalCodeCobranza: Yup.string().required("Requerido"),
      addressCobranza: Yup.string().required("Requerido"),
    }),

    onSubmit: (values) => {
      setIsLoading(true);

      apiClient
        .addressFormData(values, nominaId)
        .then((data) => {
          apiClient.sendSmsCdc();
          if (localStorage.getItem("preCreditoLocal")) {
            var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
            if (
              credito.idProcessMarketing !== "" &&
              credito.typeLoanMarketing === "Personal"
            ) {
              var dd = {
                processName: "address-form-finish",
              };
              updatePreCredito({
                ...preCredito,
                stepMarketing: "address-form-finish",
              });
              updateMarketing(credito.idProcessMarketing, dd);
            }
          }
          nextStep("CDC");
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          if (e.message === "city ​​outside the coverage area") {
            updateCredito({
              estatusSolicitud: "solicitud-no-aprobada",
            });
            history.push("/RECHAZADO/personal/DENIED");
          }
          setMessage(e.message);
          setSeverity("error");
          setOpenMessage(true);
        });
    },
  });

  /*const formik = useFormik({
    initialValues: {
      postalCode: "",
      street: "",
      exteriorNumber: "",
      interiorNumber: "",
      delegation: "",
      state: "",
      city: "",
      suburb: "",
      propertyType: "",
      antiquity: 0,
      antiquityMonth: 0,
      homePhone: "",
    },
    validationSchema: Yup.object({
      postalCode: Yup.string()
        .matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)
        .required(),
      street: Yup.string().required(),
      exteriorNumber: Yup.string().required(),
      state: Yup.string().required(),
      city: Yup.string().required(),
      delegation: Yup.string().required(),
      suburb: Yup.string().required(),
      propertyType: Yup.string().required(),
      antiquity: Yup.number().required(),
      antiquityMonth: Yup.number().required(),
      homePhone: Yup.string().matches(/[0-9]+/),
    }),
    onSubmit: (values) => {
      setIsLoading(true);

      apiClient
        .addressFormData(values, nominaId)
        .then((data) => {
          apiClient.sendSmsCdc();
          if (localStorage.getItem("preCreditoLocal")) {
            var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
            if (
              credito.idProcessMarketing !== "" &&
              credito.typeLoanMarketing === "Personal"
            ) {
              var dd = {
                processName: "address-form-finish",
              };
              updatePreCredito({
                ...preCredito,
                stepMarketing: "address-form-finish",
              });
              updateMarketing(credito.idProcessMarketing, dd);
            }
          }
          nextStep("CDC");
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          if (e.message === "city ​​outside the coverage area") {
            updateCredito({
              estatusSolicitud: "solicitud-no-aprobada",
            });
            history.push("/RECHAZADO/personal/DENIED");
          }
        });
    },
  });*/

  /*useEffect(() => {
    const setPostalCode = () => {
      if (formik.values.postalCode.trim().length == 5) {
        const postalCode = formik.values.postalCode.trim();

        apiClient
          .getAdrressFromZipCode(postalCode)
          .then((data) => {
            formik.setFieldValue("state", data.estado);
            formik.setFieldValue("city", data.ciudad || data.municipio);
            formik.setFieldValue("delegation", data.municipio);
            formik.setFieldValue("postalCode", formik.values.postalCode);
            setColonias(data?.colonias);
          })
          .catch((e) => console.log(e));
      }
    };

    if (formik.values.postalCode !== "") {
      setPostalCode();
    }
  }, [formik.values.postalCode]);*/

  useEffect(() => {
    const setPostalCode = () => {
      if (values.postalCode.trim().length == 5) {
        const postalCode = values.postalCode.trim();

        apiClient
          .getAdrressFromZipCode(postalCode)
          .then((data) => {
            if ( data.estado != 'error' ) {
              setFieldValue("state", data.estado);
              setFieldValue("city", data.ciudad || data.municipio);
              setFieldValue("delegation", data.municipio || data.ciudad);
              setFieldValue("postalCode", postalCode);
              setFieldValue("country", "Mexico");
              setZipData(data);
            } else {

              setFieldValue("state", "");
              setFieldValue("city","");
              setFieldValue("country", "");
              setFieldValue("delegation", "");
              setZipData({
                ciudad: "",
                estado: "",
                municipio: "",
                delegation: "",
                colonias: [],
              });

              setMessage("Debes introducir un código postal válido.");
              setSeverity("error");
              setOpenMessage(true);
            }
          })
          .catch((e) => console.log(e));
      } else {
        setFieldValue("state", "");
        setFieldValue("city","");
        setFieldValue("delegation","");
        setFieldValue("country", "");
        setFieldValue("delegation", "");
        setZipData({
          ciudad: "",
          estado: "",
          municipio: "",
          delegation: "",
          colonias: [],
        });
      }
    };

    if (values.postalCode !== "") {
      setPostalCode();
    }
  }, [values.postalCode]);

  useEffect(() => {
    const setPostalCodeCobranza = () => {
      if (values.postalCodeCobranza.trim().length == 5) {
        const postalCode = values.postalCodeCobranza.trim();

        apiClient
          .getAdrressFromZipCode(postalCode)
          .then((data) => {
            if ( data.estado != 'error' ) {
              setFieldValue("stateCobranza", data.estado);
              setFieldValue("cityCobranza", data.ciudad || data.municipio);
              setFieldValue("postalCodeCobranza", values.postalCodeCobranza);
              setFieldValue("countryCobranza", "Mexico");
              setZipDataCobranza(data);
            } else {

              setFieldValue("stateCobranza", "");
              setFieldValue("cityCobranza","");
              setFieldValue("countryCobranza", "");
              setZipDataCobranza({
                ciudad: "",
                estado: "",
                municipio: "",
                colonias: [],
              });

              setMessage("Debes introducir un código postal válido.");
              setSeverity("error");
              setOpenMessage(true);
            }
          })
          .catch((e) => console.log(e));
      } else {
        setFieldValue("stateCobranza", "");
        setFieldValue("cityCobranza","");
        setFieldValue("countryCobranza", "");
        setZipDataCobranza({
          ciudad: "",
          estado: "",
          municipio: "",
          colonias: [],
        });
      }
    };

    if (values.postalCodeCobranza !== "") {
      setPostalCodeCobranza();
    }
  }, [values.postalCodeCobranza]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="grey-background"
    >
      <Navbar isLoggedIn />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <br />
      <LoanType
        loanType={localStorage.getItem("loanType")}
        userValidated={true}
      />
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 mb-5"
      >
        <div className="card card-monto">
          <div className="container">
            <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
            <h2 className="text-light-blue text-end">
              {" "}
              {amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="row"
      >
        <div className="col-3">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="card card-monto">
              <div className="container">
                <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
                <h2 className="text-light-blue text-end">
                  {" "}
                  {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="text-center">
            <h3 className="sol1-tittle">Solicitud de préstamo</h3>
            <p>Por favor, rellena los siguientes campos con tu información</p>
          </div>
        </div>
        <div className="col-3"></div>
      </motion.div>
      {/* STEPS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-none d-sm-none d-md-block p-0 m-0 "
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step-blue">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step-blue">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step-blue">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 "
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step-blue">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step-blue">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step-blue">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      {/* CARDS */}
      
      <div className="d-flex justify-content-center mt-4 container">
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <motion.div
            initial={{ y: "50%", opacity: 0, scale: 0.5 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: "50%", transition: { duration: 0.8 } }}
            transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
            className="container"
          >
            <p className="txt-blue-strong">
              Escriibe tu dirección como aparece en tu comprobante de domicilio vigente
            </p>
            <hr className="mt-2 mb-2" />
            <p className="txt-blue-strong">
              Domicilio de casa
            </p>
            <form id="div-perfil" onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="street" className="form-label sol1-tittle">
                  Calle *
                </label>
                <input
                  type="input"
                  //id="street"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    const value = e.target.value || "";
                    setFieldValue(
                      "street",
                      validateString(value.toUpperCase())
                    );
                  }}
                  value={values.street}
                  onBlur={handleBlur}
                  className={
                    errors.street ? " form-control is-invalid" : "form-control"
                  }
                  id="street"
                  name="street"
                />
                {errors.street && (
                  <div className="invalid-feedback">
                    El campo calle es requerido
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exteriorNumber"
                      className="form-label sol1-tittle"
                    >
                      Numero exterior *
                    </label>
                    <input
                      type="input"
                      id="exteriorNumber"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "exteriorNumber",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.exteriorNumber}
                      onBlur={handleBlur}
                      className={
                        errors.exteriorNumber
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errors.exteriorNumber && (
                      <div className="invalid-feedback">
                        El número exterior es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="interiorNumber"
                      className="form-label sol1-tittle"
                    >
                      Número interior
                    </label>
                    <input
                      type="input"
                      id="interiorNumber"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "interiorNumber",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.interiorNumber}
                      onBlur={handleBlur}
                      className={
                        errors.interiorNumber
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="address" className="form-label sol1-tittle">
                  Entre Calles *
                </label>
                <input
                  type="input"
                  //id="street"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    const value = e.target.value || "";
                    setFieldValue(
                      "address",
                      validateString(value.toUpperCase())
                    );
                  }}
                  value={values.address}
                  onBlur={handleBlur}
                  className={
                    errors.address ? " form-control is-invalid" : "form-control"
                  }
                  id="address"
                  name="address"
                />
                {errors.address && (
                  <div className="invalid-feedback">
                    El campo entre calles es requerido
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="postalCode"
                      className="form-label sol1-tittle"
                    >
                      Código postal *
                    </label>
                    <input
                      type="input"
                      id="postalCode"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        if (value.trim().length <= 5) {
                          setFieldValue(
                            "postalCode",
                            value.trim()
                          )
                        }
                      }}
                      value={values.postalCode}
                      onBlur={handleZipBlur}
                      className={
                        errors.postalCode
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errors.postalCode && (
                      <div className="invalid-feedback">
                        El código postal es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="suburb" className="form-label sol1-tittle">
                      Colonia *
                    </label>
                    <select
                      id="suburb"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.suburb}
                      onBlur={handleBlur}
                      className={
                        errors.suburb
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                    >
                      <option value="">Seleccione una opción</option>
                      {zipData.colonias.map((colonia) => (
                        <option key={colonia.colonia} value={colonia.colonia}>
                          {colonia.colonia}
                        </option>
                      ))}
                    </select>
                    {errors.suburb && (
                      <div className="invalid-feedback">
                        El campo colonia es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label sol1-tittle">
                      Ciudad *
                    </label>
                    <input
                      type="input"
                      id="city"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.city}
                      onBlur={handleBlur}
                      className={
                        errors.city
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="delegation" className="form-label sol1-tittle">
                      Delegación *
                    </label>
                    <input
                      type="input"
                      id="delegation"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.delegation}
                      onBlur={handleBlur}
                      className={
                        errors.delegation
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label sol1-tittle">
                      Pais *
                    </label>
                    <input
                      type="input"
                      id="country"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.country}
                      onBlur={handleBlur}
                      className={
                        errors.country
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="state" className="form-label sol1-tittle">
                      Estado *
                    </label>
                    <input
                      type="input"
                      id="state"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.state}
                      onBlur={handleBlur}
                      className={
                        errors.state
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="homePhone"
                      className="form-label sol1-tittle"
                    >
                      Teléfono fijo
                    </label>
                    <input
                      type="input"
                      id="homePhone"
                      aria-describedby="emailHelp"
                      onChange={handleChangePhone}
                      value={values.homePhone}
                      onBlur={handleBlur}
                      className={
                        errors.homePhone
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errors.homePhone && (
                      <div className="invalid-feedback">
                        El campo teléfono es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="antiquity"
                      className="form-label sol1-tittle"
                    >
                      Antigüedad en el domicilio
                    </label>
                    <select
                      id="antiquity"
                      name="antiquity"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.antiquity}
                      onBlur={handleBlur}
                      className={
                        errors.antiquity
                          ? "form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="0">Menos de 6 meses</option>
                      <option value="1">1 año</option>
                      <option value="2">2 años</option>
                      <option value="3">3 años</option>
                      <option value="4">4 años</option>
                      <option value="5">5 años</option>
                      <option value="6">Mas de 6 años</option>
                    </select>
                    {errors.street && (
                      <div className="invalid-feedback">
                        El antigueada domicilio es requerido
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="propertyType"
                      className="form-label sol1-tittle"
                    >
                      Tipo de propiedad*
                    </label>
                    <select
                      className={
                        errors.propertyType
                          ? ' form-select slect-blue text-light is-invalid'
                          : 'form-select slect-blue text-light'
                      }
                      id="propertyType"
                      name="propertyType"
                      value={values.propertyType}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="FAMILIAR">Familiar</option>
                      <option value="PROPIA">Propia</option>
                      <option value="RENTADA">Rentada</option>
                    </select>
                    {errors.propertyType && (
                      <div className="invalid-feedback">
                        El campo tipo propiedad es requerido
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="antiquityMonth"
                      className="form-label sol1-tittle"
                    >
                      Antigüedad en el domicilio
                    </label>
                    <input
                      type="number"
                      placeholder="Meses"
                      id="antiquityMonth"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.antiquityMonth}
                      onBlur={handleBlur}
                      className={
                        errors.antiquityMonth
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                </div> */}
              </div>
              <hr className="mt-2 mb-2" />
              <p className="txt-blue-strong">
                Domicilio de cobranza
                <span style={{ fontSize: "12px"}} className=""><br></br>*Si los abonos serán por transferencia o depósito agregar el mismo domicilio</span>
              </p>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-2">
                    <div className="form-check">
                      <input className="form-check-input checkbox-terms" type="checkbox" value="" id="checkSameAddress" onChange={(e) => handleSameAddress(e)} />
                      <label className="form-check-label text-check" htmlFor="checkSameAddress">
                          Mismo domicilio de casa
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="streetCobranza" className="form-label sol1-tittle">
                      Calle *
                    </label>
                    <input
                      type="input"
                      //id="street"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "streetCobranza",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.streetCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.streetCobranza ? " form-control is-invalid" : "form-control"
                      }
                      id="street"
                      name="street"
                    />
                    {errors.streetCobranza && (
                      <div className="invalid-feedback">
                        El campo calle es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exteriorNumberCobranza"
                      className="form-label sol1-tittle"
                    >
                      Numero exterior *
                    </label>
                    <input
                      type="input"
                      id="exteriorNumberCobranza"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "exteriorNumberCobranza",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.exteriorNumberCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.exteriorNumberCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errors.exteriorNumberCobranza && (
                      <div className="invalid-feedback">
                        El número exterior es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="interiorNumberCobranza"
                      className="form-label sol1-tittle"
                    >
                      Número interior
                    </label>
                    <input
                      type="input"
                      id="interiorNumberCobranza"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "interiorNumberCobranza",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={values.interiorNumberCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.interiorNumberCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="addressCobranza" className="form-label sol1-tittle">
                  Entre Calles *
                </label>
                <input
                  type="input"
                  //id="street"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    const value = e.target.value || "";
                    setFieldValue(
                      "addressCobranza",
                      validateString(value.toUpperCase())
                    );
                  }}
                  value={values.addressCobranza}
                  onBlur={handleBlur}
                  className={
                    errors.addressCobranza ? " form-control is-invalid" : "form-control"
                  }
                  id="address"
                  name="address"
                />
                {errors.addressCobranza && (
                  <div className="invalid-feedback">
                    El campo entre calles es requerido
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="postalCodeCobranza"
                      className="form-label sol1-tittle"
                    >
                      Código postal *
                    </label>
                    <input
                      type="input"
                      id="postalCodeCobranza"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        if (value.trim().length <= 5) {
                          setFieldValue(
                            "postalCodeCobranza",
                            value.trim()
                          )
                        }
                      }}
                      value={values.postalCodeCobranza}
                      onBlur={handleZipBlurCobranza}
                      className={
                        errors.postalCodeCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errors.postalCodeCobranza && (
                      <div className="invalid-feedback">
                        El código postal es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="suburbCobranza" className="form-label sol1-tittle">
                      Colonia *
                    </label>
                    <select
                      id="suburbCobranza"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.suburbCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.suburbCobranza
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                    >
                      <option value="">Seleccione una opción</option>
                      {zipDataCobranza.colonias.map((colonia) => (
                        <option key={colonia.colonia} value={colonia.colonia}>
                          {colonia.colonia}
                        </option>
                      ))}
                    </select>
                    {errors.suburbCobranza && (
                      <div className="invalid-feedback">
                        El campo colonia es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="cityCobranza" className="form-label sol1-tittle">
                      Ciudad *
                    </label>
                    <input
                      type="input"
                      id="cityCobranza"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.cityCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.cityCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="countryCobranza" className="form-label sol1-tittle">
                      Pais *
                    </label>
                    <input
                      type="input"
                      id="countryCobranza"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.countryCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.countryCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="stateCobranza" className="form-label sol1-tittle">
                      Estado *
                    </label>
                    <input
                      type="input"
                      id="stateCobranza"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.stateCobranza}
                      onBlur={handleBlur}
                      className={
                        errors.stateCobranza
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                </div>
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="antiquityMonth"
                      className="form-label sol1-tittle"
                    >
                      Antigüedad en el domicilio
                    </label>
                    <input
                      type="number"
                      placeholder="Meses"
                      id="antiquityMonth"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={values.antiquityMonth}
                      onBlur={handleBlur}
                      className={
                        errors.antiquityMonth
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  {/* <div className="d-flex justify-content-center mt-5 mb-4">
                    <a
                      href="/solicitud/personal/5"
                      className="btn btn-shadow-2"
                    >
                      <img
                        src={flechai}
                        alt="PRADO'S"
                        className="img-fluid mrirht"
                        width="10"
                      />
                      Regresar
                    </a>
                  </div> */}
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <button
                      type="submit"
                      className="btn btn-shadow-2"
                      disabled={!isValid}
                    >
                      Continuar
                      <img
                        src={flechad}
                        alt="PRADO'S"
                        className="img-fluid mleft"
                        width="10"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
      <Footer />
    </motion.div>
  );
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
  nominaId: personalCreditReducer?.nominaId ?? "",
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      nextStep,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
