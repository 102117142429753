import React, { useEffect, useState } from "react";
import "../../css/style.css";
import logo from "../../img/logo.png";
import logo_balnco from "../../img/logo_blanco.png";
import icono_user from "../../img/icono-user.png";
import icono_userm from "../../img/icono-user-azul.png";
import icono_whatsm from "../../img/icono-whats-azul.png";
import logout from "../../img/logout.png";
import { TramiteCreditoContext } from "../../context/TramiteCreditoProvider";
import { UserContext } from "../../context/UserProvider";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import logo_pabs2 from "../../img/logo_pabs.png";
import icono_mujer from "../../img/icono_mujer.png";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",

  maxHeight: "500px",
  background: "radial-gradient(circle at top left, #005593, #36c9d0)",
  boxShadow: 29,
  overflowY: "auto",
};

interface NavbarProps {
  isLoggedIn?: boolean;
}

export default function Navbar(props: NavbarProps) {
  const { isLoggedIn = false } = props;

  const { userMain, updateUser } = React.useContext(UserContext);
  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
  const [creditoE, setCreditoE] = useState(null);
  const setCredit = (credit) => {
    updateUser({
      ...userMain,
      creditType: credit,
    });
  };
  const history = useHistory();
  const [open2, setOpen2] = React.useState(false);
  const handleClose = () => setOpen2(false);
  function handleClick(e: { preventDefault: () => void }) {
    e.preventDefault();
    updateCredito({
      idPrestamo: "",
      fecha_solicitud: "",
      isApproved: false,
      isDelivered: false,
      estatusSolicitud: "sin-credito",
      importe: 0,
      paymentStatus: "0", // (0 = nuevo, 1 = en proceso, 2 = aprobado, 3 = Rechazado)
      importe_interes: 0,
      total_importe: 0,
      numero_plazo: 0,
      importe_liquidar: 0,
      importe_pendiente: 0,
      detalles: [],
      limitAmountCredit: 20000, // limite de dinero a prestar
      limitTermCredit: 12, // limite de meses
      tasa: 70,
    });
    localStorage.clear();
    updateUser({ ...userMain, nombre: "Iniciar Sesión", apellidoPaterno: "" });
    return history.push("/login");
  }
  function redirectComponent(e: { preventDefault: () => void }) {
    e.preventDefault();
    var credit = JSON.parse(localStorage.getItem("creditoLocal"));
    console.log(credito);
    if (credit.creditType === "pabs") {
      if (credito.estatusSolicitud === "aprobado") {
        return history.push("/dashboard");
      }
      return history.push("/PABS");
    } else if (
      credit.creditType === "personal" &&
      credit.estatusSolicitud !== "solicitud-no-aprobada"
    ) {
      return history.push("/prestamo-personal");
    } else if (credit.estatusSolicitud === "solicitud-no-aprobada") {
      if (credit.creditType === "pabs") {
        return history.push("/RECHAZADO/pabs/DENIED");
      } else if (credit.creditType === "personal") {
        return history.push("/RECHAZADO/personal/DENIED");
      }
    } else {
      return history.push("/dashboard");
    }
  }

  const [mobileNav, setMobileNav] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setMobileNav(open);
    };

  useEffect(() => {
    if (localStorage.getItem("creditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("creditoLocal"));
      setCreditoE(credito);
    }
  }, []);
  return (
    <div>
      <Modal
        keepMounted
        open={open2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* //<ScrollView width="100%" height="100%"> */}
        <Grid container spacing={2} sx={style}>
          <div className="modal-content">
            <div className="d-flex justify-content-end p-1  ">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center mb-3">
                <img
                  src={logo_balnco}
                  alt="PRADO'S"
                  width="220"
                  className="img-fluid"
                />
              </div>
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 p-3" style={{ background: "#ff00006E" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={logo_pabs2}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Tengo un <br /> título PABS{" "}
                    </h4>
                    <h5 className="rLight">
                      Tu crédito ya está <br />
                      Pre-autorizado
                    </h5>

                    <div className="">
                      <a
                        href="/solicitud-monto/titulo"
                        className="btn btn-primary btn-conoce-azul btn-small"
                        onClick={() => setCredit("titulo")}
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                    <div className="mt-4">
                      <p className="rLight mt-4">
                        ¿Qué es un Título PABS? <br />
                        <a>Click aqui</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 p-3" style={{ background: "#ffff0040" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={icono_mujer}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Quiero un <br /> crédito personal{" "}
                    </h4>
                    <h5 className="rLight">
                      Tu crédito en <br />
                      menos de 24 hrs
                    </h5>

                    <div className="">
                      <a
                        href="/solicitud-monto/personal"
                        className="btn btn-primary btn-conoce-azul btn-small"
                        onClick={() => setCredit("personal")}
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Modal>
      {/* ESCRITORIO NAVBAR */}
      <div className="d-none d-sm-none d-md-block p-0 m-0 ">
        <nav className="navbar navbar-expand-lg navbar-light p-0 m-0 nav-pc">
          <div className="container-fluid p-0 m-0">
            <a
              className="navbar-brand navbar-brand-left"
              href="https://percapita.mx/"
            >
              <img src={logo} alt="PRADO'S" className="img-fluid" />
            </a>
            <h6 className="text-number">
              Llámanos:{" "}
              <a
                href="tel:+523336168059"
                className="a_none_style text-blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                33 3616-8059
              </a>
            </h6>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                {userMain.nombre === "Iniciar Sesión" ? (
                  <>
                    <li className="nav-item">
                      <a
                        className="nav-link nav-text"
                        href="https://percapita.mx/nosotros"
                      >
                        {" "}
                        <h6 className="text-nav">Sobre nosotros</h6>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link nav-text"
                        href="https://percapita.mx/Financiamiento"
                      >
                        {" "}
                        <h6 className="text-nav">Financiamiento</h6>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link nav-text" href="/">
                        {" "}
                        <h6 className="text-nav">Crédito PABS</h6>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link nav-text"
                        href="https://percapita.mx/blog"
                      >
                        {" "}
                        <h6 className="text-nav">Blog</h6>
                      </a>
                    </li>
                  </>
                ) : (
                  ""
                )}

                {userMain.nombre !== "Iniciar Sesión" ? (
                  <li className="nav-item">
                    <a
                      className="nav-link nav-text"
                      href="#"
                      onClick={handleClick}
                    >
                      {" "}
                      <h6 className="text-nav">Cerrar Sesión</h6>
                    </a>
                  </li>
                ) : (
                  <li className="nav-item">
                    <a className="nav-link nav-text" href="/login">
                      <img
                        src={icono_user}
                        alt="PRADO'S"
                        className="img-fluid"
                      />
                    </a>
                  </li>
                )}
                {isLoggedIn ? (
                  <li className="nav-item nav-credit text-center">
                    <a
                      className="nav-link nav-text "
                      href="#"
                      onClick={redirectComponent}
                    >
                      {' '}
                      <h6 className="text-credit">Mi crédito</h6>
                    </a>
                  </li>
                ) : (
                  <li className="nav-item nav-credit text-center">
                    <a
                      className="nav-link nav-text "
                      href="#"
                      onClick={() => setOpen2(true)}
                    >
                      {' '}
                      <h6 className="text-credit">Solicitar crédito</h6>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* SMARTPHONE NAVBAR */}
      <div className="d-block d-sm-block d-md-none p-0 m-0 ">
        <nav className="navbar navbar-expand-lg navbar-light p-0 m-0 ">
          <div className="container-fluid p-0 m-0 ">
            <a className="navbar-brand" href="/">
              <img src={logo} alt="PRADO'S" className="img-fluid" />
            </a>
            {userMain.nombre === "Iniciar Sesión" ? (
              <a className="nav-image-movil" href="/login">
                <img src={icono_userm} alt="PRADO'S" className="img-fluid" />
              </a>
            ) : (
              ""
            )}

            {userMain.nombre !== "Iniciar Sesión" ? (
              <a className="nav-image-movil" href="/login">
                <img src={logout} alt="PRADO'S" className="img-fluid" />
              </a>
            ) : (
              ""
            )}
            <a className="nav-image-movil" href="#">
              <img src={icono_whatsm} alt="PRADO'S" className="img-fluid" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                {userMain.nombre === "Iniciar Sesión" ? (
                  <>
                    <li className="nav-item">
                      <a
                        className="nav-link nav-text"
                        href="https://percapita.mx/nosotros"
                      >
                        {" "}
                        <h6 className="text-nav">Sobre nosotros</h6>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link nav-text"
                        href="https://percapita.mx/Financiamiento"
                      >
                        {" "}
                        <h6 className="text-nav">Financiamiento</h6>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link nav-text" href="/">
                        {" "}
                        <h6 className="text-nav">Crédito PABS</h6>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link nav-text"
                        href="https://percapita.mx/blog"
                      >
                        {" "}
                        <h6 className="text-nav">Blog</h6>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link nav-text" href="/login">
                        {" "}
                        <h6 className="text-nav">Iniciar Sesión</h6>
                      </a>
                    </li>
                    <li className="nav-item nav-credit-movil">
                      <a className="nav-link nav-text " href="/solicitud-monto">
                        {" "}
                        <h6 className="text-credit">Solicitar crédito</h6>
                      </a>
                    </li>
                  </>
                ) : (
                  ""
                )}

                {userMain.nombre !== "Iniciar Sesión" ? (
                  <li className="nav-item">
                    <a
                      className="nav-link nav-text"
                      href="#"
                      onClick={handleClick}
                    >
                      {" "}
                      <h6 className="text-nav">Cerrar Sesión</h6>
                    </a>
                  </li>
                ) : (
                  <li className="nav-item">
                    <a className="nav-link nav-text" href="/login">
                      <img
                        src={icono_user}
                        alt="PRADO'S"
                        className="img-fluid"
                      />
                    </a>
                  </li>
                )}
                {isLoggedIn ? (
                  <li className="nav-item nav-credit text-center">
                    <a
                      className="nav-link nav-text "
                      href="#"
                      onClick={redirectComponent}
                    >
                      {' '}
                      <h6 className="text-credit">Mi crédito</h6>
                    </a>
                  </li>
                ) : (
                  <li className="nav-item nav-credit text-center">
                    <a
                      className="nav-link nav-text "
                      href="#"
                      onClick={() => setOpen2(true)}
                    >
                      {' '}
                      <h6 className="text-credit">Solicitar crédito</h6>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}